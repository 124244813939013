import React, { useState, useEffect } from "react";
import { Empty, Image } from "antd";
import { getPatientUploadedReports } from "Utils/Services/Practitioner/Appointments";

const PatientUploadedReportsTab = ({ patientId }) => {
  const [reports,setReports] = useState([]);

  useEffect(() => {
    const getData = async () => {
      getPatientUploadedReports(patientId).then((response) => {
        setReports(response.data);
      });
    };
    if (patientId) {
      getData();
    }
  }, [patientId]);

  if(!patientId){
    return  <div className="report-container">
      <Empty
        description="Patient ID is not available"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    </div>
  }
  
  
  return (
    <div className="report-container">
        <p>Showing {reports.length} report{reports.length === 0 ? "":"s"}</p>
      {reports.length ? (
        reports.map(
            (item, index) => (
              <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center", margin:"1px"}}>
                {
                  item.report && !item.report.endsWith(".pdf")?(
                    item.report && <Image 
                    src={item.report} 
                    alt={`report-${item.title}`}
                    key={index}
                    style={{width:"400px",height:"380px",borderRadius:"5px"}}
                  />
                  ):(
                      <iframe
                        title="PDF Viewer"
                        src={`https://docs.google.com/gview?url=${item.report}&embedded=true`}
                        width="80%"
                        height="500"
                        frameBorder="0"
                      />
                  )
                }
              </div>
            )
          )
      ) : (
        <Empty
          description="No Reports Found"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </div>
  );
};

export default PatientUploadedReportsTab;
