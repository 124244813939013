import React, { useEffect, useState } from 'react';
import RegisterBG from 'Images/bg/login.jpg';
import { 
    Typography,
    Form,
    Input,
    Button,
    Select,
    Tabs,
} from  'antd';

import 'react-phone-input-2/lib/style.css';
import AntdCustomMessage, { antdMessageTypes } from 'components/AntdCustomMessage';
import { useHistory } from "react-router";
import {  checkOrAddArea, checkOrAddStreet, getAreasList, getAreasOfEmirate, getDepartmentsList, getEmiratesList, getStreetsList, updateHospitalOnboardingProfile } from 'Utils/Services/Onboarding';
import { format } from 'date-fns';
import Cookies from "universal-cookie";
import { DecryptString } from 'Utils/Constants';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

const {Option} = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;
const providerTypes = [
    {
        label: "Hospital/Clinic",
        id: "hospital",
        value: "hospital",
    }
]


const HospitalOnboarding = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);

    const [adminEmirates, setAdminEmirates] = useState([]);
    const [adminAreas, setAdminAreas] = useState([]);
    const [adminStreets, setAdminStreets] = useState([]);
    const [adminPincodes, setAdminPincodes] = useState([]);
    const [pincodes, setPincodes] = useState([]);
    const [adminDepartments, setAdminDepartments] = useState([]);
    const [streets, setStreets] = useState([]);
    const [areas, setAreas] = useState([]);

    const [selectedEmirate, setSelectedEmirate] = useState('');
    const [activeTab, setActiveTab] = useState('1');
    const [onboardingData, setOnboardingData] = useState({
        address:"",
        building: "",
        floor:"",
        area_id:"",
        street_id:"",
        emirate_id: "",
        pincode:"",
        telephone:"",
        office_number:"",
        type: "hospital",
        available_departments:[],
        about_us:"",
        order_number:"",
        created_at:"",
        updated_at:"",
    });

    const handleStreetSearch = (value) => {
        const exist = adminStreets?.find((street)=>street?.street === value);
        !exist && setStreets([...adminStreets,{
            id: value,
            value: value,
            label: value,
            street: value
        }]);
      };
    
      const handleAreaSearch = (value) => {
        const exist = adminAreas?.find((area)=>area?.area === value);
        !exist && setAreas([...adminAreas,{
            id: value,
            value: value,
            label: value,
            area: value
        }]);
      };

    const handleEmirateChange = async (value) => {
        setSelectedEmirate(value);
        form.setFieldsValue({
          area_id: "",
        });
        const areasResponse = await getAreasOfEmirate({emirate_id:value});
        if(areasResponse?.status){
          const emirates = areasResponse?.data?.map(eachArea => ({
              value: eachArea?.id.toString(),
              id: eachArea?.id.toString(),
              label: eachArea?.area,
              area: eachArea?.area,
          }));
          setAdminAreas([...emirates]);
          setAreas([...emirates]);
        };
      };

    const handlePincodeSearch = (value) => {
        const exist = adminPincodes?.find((pin)=>pin?.pincode === value);
        !exist && setPincodes([...adminPincodes,{
            id:value,
            value:value,
            label: value
        }])
    };
    
    const handleFinishTab1 = async () => {
        try {
            await form.validateFields([
                'telephone',
                'office_number',
                'address',
                'floor',
                'building',
                'emirate_id',
                'area_id', 
                'street_id',
                'pincode'
            ]);
            setActiveTab('2');
        } catch (errorInfo) {
            // console.error('Validation failed:', errorInfo);
        }
    };

    const handlePrevTab = () => {
        setActiveTab('1');
    };

    const FetchEmiratesList = async () => {
        const emiratesResponse = await getEmiratesList();
        if(emiratesResponse?.status){
            const emirates = emiratesResponse?.data?.map(eachEmirate => ({
                value: eachEmirate?.id.toString(),
                id: eachEmirate?.id.toString(),
                label: eachEmirate?.emirate
            }));
            setAdminEmirates([...emirates]);
        }
    };

    const FetchAreasList = async () => {
        const areasResponse = await getAreasList();
        if(areasResponse?.status){
            const emirates = areasResponse?.data?.map(eachArea => ({
                value: eachArea?.id.toString(),
                id: eachArea?.id.toString(),
                label: eachArea?.area,
                area: eachArea?.area,
            }));
            setAdminAreas([...emirates]);
            setAreas([...emirates]);
        }
    };

    const FetchStreetsList = async () => {
        const streetsResponse = await getStreetsList();
        if(streetsResponse?.status){
            const streets = streetsResponse?.data?.map(eachStreet => ({
                value: eachStreet?.id.toString(),
                id: eachStreet?.id.toString(),
                label: eachStreet?.street,
                street: eachStreet?.street,
            }));
            setAdminStreets([...streets]);
            setStreets([...streets]);
        }
    };

    const FetchDepartmentsList = async () => {
        const departmentsResponse = await getDepartmentsList();
        if(departmentsResponse?.status){
            const departments = departmentsResponse?.data?.map(eachDepartment => ({
                value: eachDepartment?.id.toString(),
                id: eachDepartment?.id.toString(),
                label: eachDepartment?.department
            }));
            setAdminDepartments([...departments]);
        }
    };

    const onFinishFailed = () =>{
        setIsLoading(false);
    };

    const onFinish = async (values) => {
        setIsLoading(true);
        const street = adminStreets?.find(street =>street.id === values.street_id);
        const area = adminAreas?.find(area=>area.id === values.area_id);
        let streetId = values.street_id;
        let areaId = values.area_id;
        if(street){
          streetId = street.id;
        }else{
          const streetResponse = await checkOrAddStreet({street: values.street_id});
          streetId = streetResponse?.streetId || values?.street_id;
        }
        if(area){
          areaId = area.id;
        }else{
          const areaResponse = await checkOrAddArea({
            areaName: values.area_id,
            emirate_id: values.emirate_id
          });
          areaId = areaResponse?.areaId || values?.area_id;
        }
        
        const onboardingResponse = await updateHospitalOnboardingProfile({
            ...values,
            street_id: streetId, 
            area_id: areaId,
            available_departments: JSON.stringify(values.available_departments),
            created_at: `${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`,
            updated_at: `${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`,
        });
        if(onboardingResponse?.status){
            window.alert("Onboarding is successfully completed. Please wait for admin's approval to use your account");
            cookies.remove("userdata", { path: "/" });
            cookies.remove("token", { path: "/" });
            history.push("/login");
            window.location.reload();
        }else{
            AntdCustomMessage({
                type: antdMessageTypes.ERROR,
                content: onboardingResponse?.message || "something went wrong"
            });
        }
        setIsLoading(false);
    };

    useEffect(()=>{
        form.resetFields(['area_id']);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedEmirate])

    useEffect(()=>{
        FetchDepartmentsList();
        FetchEmiratesList();
        FetchAreasList();
        FetchStreetsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    let cookieUserData = DecryptString(cookies.get("userdata", { path: "/" }));
    let UserData = cookieUserData && JSON.parse(cookieUserData);

    if(!UserData?.type){
        return <Redirect to="/login" />;
    };

    if(UserData?.subadmin || UserData?.profile_completed){
        return <Redirect to="/hospital/dashboard" />;
    };

    return (
        <div
            style={{
                backgroundImage:`url(${RegisterBG})`,
                backgroundSize: "cover",
                minHeight: '100vh',
                display:"flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div style={{
                minHeight: "50vh",
                width: "80%",
                borderRadius: "10px",
                backgroundColor:"rgba(255,255,255,1)",
                display:"flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <div className='w-75 p-5'>
                    <Typography.Title style={{textAlign:"center"}}>Onboarding</Typography.Title>
                    <Form
                        form={form}
                        name="onBoardingForm"
                        initialValues={onboardingData}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                    >
                         <Tabs 
                            activeKey={activeTab}
                            tabBarStyle={{  
                                background: 'rgba(123, 111, 111, 0.2)', 
                                padding: '8px',
                                width: "100%",
                                fontWeight: "bold",
                                borderRadius: "5px"
                            }}
                        >
                            <TabPane tab="Location Information" key="1">
                                <Form.Item
                                    label="Telephone"
                                    name="telephone"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please input telephone number!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (value && !/^\d*$/.test(value)) {
                                                    return Promise.reject('Please enter only digits');
                                                }
                                                if (value.length < 6 || value.length > 15) {
                                                    return Promise.reject('Telephone number must be between 6 and 15 digits');
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                     <Input type="tel" pattern="^[0-9]*$"/>
                                </Form.Item>
                                <Form.Item
                                    label="Office Number"
                                    name="office_number"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please input office number!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (value && !/^\d*$/.test(value)) {
                                                    return Promise.reject('Please enter only digits');
                                                }
                                                if (value.length < 6 || value.length > 15) {
                                                    return Promise.reject('Office number must be between 6 and 15 digits');
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                     <Input type="tel" pattern="^[0-9]*$"/>
                                </Form.Item>
                                <Form.Item
                                    label="Address"
                                    name="address"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please input address!',
                                        },
                                    ]}
                                >
                                     <TextArea rows={4} />
                                </Form.Item>

                                <Form.Item
                                    name="floor"
                                    label="Enter floor"
                                    rules={[
                                        { required: true, message: 'Please input floor!' }
                                    ]}
                                >
                                   <Input type="text"/>
                                </Form.Item>

                                <Form.Item
                                    name="building"
                                    label="Enter building name"
                                    rules={[
                                        { required: true, message: 'Please input building!' }
                                    ]}
                                >
                                   <Input type="text"/>
                                </Form.Item>
                                

                                <Form.Item
                                    name="emirate_id"
                                    label="Select Emirate"
                                    rules={[
                                        { required: true, message: 'Please select emirate!' }
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select emirate"
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={handleEmirateChange}
                                    >
                                        {adminEmirates.map(emirate => (
                                            <Option key={emirate?.id} value={emirate?.id}>
                                                {emirate?.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="area_id"
                                    label="Select Area"
                                    rules={[
                                        { required: true, message: 'Please select area!' }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder="Select area"
                                        optionFilterProp="children"
                                        size="large"
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                        // onSearch={handleAreaSearch}
                                    >
                                        {
                                            areas.length && areas.map((item) => (
                                                <Select.Option 
                                                    key={item?.id} 
                                                    value={item?.id}
                                                >
                                                    {item?.area}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                
                                <Form.Item
                                    name="street_id"
                                    label="Select street"
                                    rules={[
                                        { required: true, message: 'Please select street!' }
                                    ]}
                                >
                                   <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder="Select a street"
                                        optionFilterProp="children"
                                        size="large"
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                        // onSearch={handleStreetSearch}
                                    >
                                        {
                                        streets.length && streets.map((item) => (
                                            <Select.Option 
                                                key={item?.id} 
                                                value={item?.id}
                                            >
                                                {item?.street}
                                            </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="pincode"
                                    label="Select pincode"
                                    rules={[
                                        { required: true, message: 'Please select pincode!' }
                                    ]}
                                >
                                   <Select
                                        placeholder="Please select pincode"
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                        onSearch={handlePincodeSearch}
                                    >
                                        {pincodes.map(pincode => (
                                            <Option key={pincode.id} value={pincode.label}>
                                                {pincode.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    wrapperCol={{
                                        span: 24,
                                    }}
                                    className='text-center'
                                >
                                    <Button 
                                        type="primary" 
                                        onClick={handleFinishTab1}
                                    >
                                        Next
                                    </Button>
                                </Form.Item>
                            </TabPane>
                            <TabPane tab="General Information" key="2">
                            <Form.Item
                            label="Select"
                            name="type"
                            rules={[
                                {
                                required: true,
                                message: 'Please select type',
                                },
                            ]}
                        >
                            <Select
                                style={{ width: "100%" }}
                                options={providerTypes}
                                disabled
                            />
                        </Form.Item>
                               
                                <Form.Item
                                    name="available_departments"
                                    label="Select atleast one department"
                                    rules={[
                                        { required: true, message: 'Please select at least one department!' }
                                    ]}
                                >
                                    <Select 
                                        mode="multiple" 
                                        placeholder="Please select departments"
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                    >
                                        {adminDepartments.map((department) => (
                                            <Option key={department.id} value={department.id}>
                                            {department.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                
                               

                                <Form.Item
                                    label="About Us"
                                    name="about_us"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please input about us!',
                                        },
                                    ]}
                                >
                                    <Input type="text"/>
                                </Form.Item>
                                <div className="d-flex flex-row align-items-center justify-between">
                                
                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Button 
                                        type="primary" 
                                        onClick={handlePrevTab}
                                    >
                                        Prev
                                    </Button>
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Button 
                                        type="primary" 
                                        htmlType="submit"
                                        loading={isLoading}
                                    >
                                        Submit
                                    </Button>
                                </Form.Item>
                                </div>
                            </TabPane>
                        </Tabs>     
                    </Form>
                </div>
                
            </div>
        </div>
    )
}

export default HospitalOnboarding