import React, { useEffect, useState, useContext } from "react";
import ReactDOM from "react-dom";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import {
  getDashboardCounts,
  getDashboardAppointmentsChartData,
} from "Utils/Services/Hospital/Dashboard";
import {
  getAppointments,
  getPackageAppointments,
} from "Utils/Services/Hospital/Appointments";
import { getProcedureRequests } from "Utils/Services/Hospital/MedicalProcedures";
import AppointmentsIcon from "Images/dashboard-icons/appointment.svg";
import PackagesAppointmentsIcon from "Images/dashboard-icons/hospital/package-calendar.png";
import MyPractitionersIcon from "Images/dashboard-icons/doctor.png";
import PatientsIcon from "Images/dashboard-icons/patient.svg";
import { DatePicker } from "antd";
import CardMetrics from "views/components/dashboard/CardMetrics";
import { isMobile } from "react-device-detect";
import AppointmentStatusChart from "views/components/dashboard/AppointmentStatusChart";
import TodayAppointments from "views/components/dashboard/HospitalTodaysAppointments";
import AppointmentsTypeChart from "views/components/dashboard/AppointmentsTypeChart";
import TodayProcedureRequests from "views/components/dashboard/TodaysProcedureRequests";
import AppointmentsChart from "views/components/dashboard/AppointmentsChart";
import PractitionerAppointmentsChart from "views/components/dashboard/PractitionerAppointmentsChart";
import { DateRanges, DATE_FORMAT } from "Utils/Constants";
import moment from "moment";
import ProcedureRequestsChart from "views/components/dashboard/ProcedureRequestsChart";
import { modelTodaysAppointmentsAPI } from "models/hospital/appointments.api.models";
import TodayPackageAppointments from "views/components/dashboard/TodaysPackageAppointments";
import PackageAppointmentsChart from "views/components/dashboard/PackageAppointmentsChart";
import ProcedureIcon from "Images/dashboard-icons/hospital/procedure.png";
import ProcedureRequestsIcon from "Images/dashboard-icons/hospital/request.png";
import PackagesIcon from "Images/dashboard-icons/lab/flask.png";
import { GlobalState } from "context-api";
import OnlinePaymentsIcon from "Images/dashboard-icons/hospital/online.png";
import RevenuesChart from "views/components/dashboard/RevenueChart";
import { dirham } from "components/CurrencyDirham";

const { RangePicker } = DatePicker;

const Dashboard = () => {
  const { ClinicRefreshPage, setHeading } = useContext(GlobalState);
  const INIT_DATE_RANGE = [
    moment().subtract(7, "days").startOf("day"),
    moment().endOf("day"),
  ];

  // const { socket, UserData } = useContext(GlobalState);

  const [ChartDateRange, setChartDateRange] = useState([...INIT_DATE_RANGE]);
  const [FilterDateRange, setFilterDateRange] = useState([]);

  const [CardCounts, setCardCounts] = useState({
    practitioners: "",
    patients: "",
    procedures: "",
    healthPackages: "",
    procedureRequests: "",
    practitionerAppointments: "",
    revenue: "",
    packageAppointments: "",
    onlinePackageAppointments: "",
    onlinePackageRevenue: "",
  });

  const [AppointmentsByStatus, setAppointmentsByStatus] = useState({
    pending: 0,
    upcoming: 0,
    completed: 0,
    other: 0,
    cancelled: 0,
  });

  const [AppointmentsByTypes, setAppointmentsByTypes] = useState({
    inPerson: 0,
    phone: 0,
    video: 0,
  });

  const [AppointmentsChartData, setAppointmentsChartData] = useState("");
  const [RevenueChartData, setRevenueChartData] = useState("");
  const [ProcedureRequestsChartData, setProcedureRequestsChartData] =
    useState("");
  const [
    PractitionerAppointmentsChartData,
    setPractitionerAppointmentsChartData,
  ] = useState("");
  const [PackageAppointmentsChartData, setPackageAppointmentsChartData] =
    useState({});
  // const [
  //   FilteredPackageAppointmentsChartData,
  //   setFilteredPackageAppointmentsChartData,
  // ] = useState({});
  const [TodaysAppointments, setTodaysAppointments] = useState("");
  const [TodayAppointmentsLoading, setTodayAppointmentsLoading] =
    useState(false);
  const [TodaysPackageAppointments, setTodaysPackageAppointments] = useState(
    []
  );
  const [TodaysPackageAppointmentsLoading, setTodaysPackageAppointmentLoading] =
    useState(false);
  const [TodaysProcedureRequests, setTodaysProcedureRequests] = useState("");
  const [TodayProcedureRequestsLoading, setTodayProcedureRequestsLoading] =
    useState(false);
  const [CountsLoading, setCountsLoading] = useState(false);
  // const [selectedPackages, setSelectedPackages] = useState([]);

  const onChangeDate = (values) => {
    if (values && values.length > 0) {
      const [startDate, endDate] = values;
      setChartDateRange([moment(startDate), moment(endDate)]);
    } else {
      setChartDateRange([...INIT_DATE_RANGE]);
    }
  };
  const onFilterDateChange = (values) => {
    if (values && values.length > 0) {
      const [startDate, endDate] = values;
      setFilterDateRange([moment(startDate), moment(endDate)]);
    } else {
      setFilterDateRange([]);
    }
  }

  const FetchDashboardcounts = async () => {
    setCountsLoading(true);
    let payload = {
      startDate:FilterDateRange[0] ?  moment(FilterDateRange[0]).format("YYYY-MM-DD"): "",
      endDate: FilterDateRange[1] ? moment(FilterDateRange[1]).format("YYYY-MM-DD"): "",
    };
    const response = await getDashboardCounts(payload);
    response && setCardCounts((counts) => ({
      ...counts,
      practitioners: response?.totalMyPractitioners,
      patients: response?.totalMyPatients,
      procedures: response?.totalMyProcedures,
      healthPackages: response?.totalMyHealthPackages,
      practitionerAppointments: response?.totalMyPractitionerAppointments,
      revenue: response?.totalMyRevenue,
      procedureRequests: response?.totalProcedureRequests,
      packageAppointments: response?.totalPackageAppointments,
      onlinePackageAppointments: response?.totalOnlinePackageAppointments,
      onlinePackageRevenue: response?.totalOnlinePackageRevenue,
    }));

    response && setAppointmentsByStatus((counts) => ({
      ...counts,
      pending: response?.appointments?.pending,
      upcoming: response?.appointments?.upcoming,
      completed: response?.appointments?.completed,
      other: response?.appointments?.other,
      cancelled: response?.appointments?.cancelled,
    }));
    response && setAppointmentsByTypes((counts) => ({
      ...counts,
      inPerson: response?.appointmentTypes?.inPerson,
      phone: response?.appointmentTypes?.phone,
      video: response?.appointmentTypes?.video,
    }));
    setCountsLoading(false);
  };

  const FetchTodayAppointments = async () => {
    setTodayAppointmentsLoading(true);
    const appointment_status = "";
    const startDate = moment().startOf("day").format("YYYY-MM-DD");
    const endDate = moment().endOf("day").format("YYYY-MM-DD");
    const limit = 10;
    const search = "";
    const start = 0;

    const response = await getAppointments(
      start,
      limit,
      appointment_status,
      search,
      startDate,
      endDate
    );
    response && setTodaysAppointments(modelTodaysAppointmentsAPI(response));
    setTodayAppointmentsLoading(false);
  };
  const FetchTodayPackageAppointments = async () => {
    setTodaysPackageAppointmentLoading(true);
    const appointment_status = "";
    const startDate = moment().startOf("day").format("YYYY-MM-DD");
    const endDate = moment().endOf("day").format("YYYY-MM-DD");
    const limit = 40;
    const search = "";
    const start = 0;

    const response = await getPackageAppointments({
      start: start,
      limit: limit,
      appointment_status: appointment_status,
      search: search,
      startDate: startDate,
      endDate: endDate,
    });
    response && setTodaysPackageAppointments(modelTodaysAppointmentsAPI(response));
    setTodaysPackageAppointmentLoading(false);
  };

  const FetchTodayProcedureRequests = async () => {
    setTodayProcedureRequestsLoading(true);
    let payload = {
      procedure_id: "all",
      startDate: moment().startOf("day").format("YYYY-MM-DD"),
      endDate: moment().endOf("day").format("YYYY-MM-DD"),
      limit: 1000,
      search: "",
      start: 0,
    };

    const response = await getProcedureRequests(payload);
    response && setTodaysProcedureRequests(response);
    setTodayProcedureRequestsLoading(false);
  };

  const FetchChartData = async () => {
    let payload = {
      startDate: moment(ChartDateRange[0]).format("YYYY-MM-DD"),
      endDate: moment(ChartDateRange[1]).format("YYYY-MM-DD"),
    };
    // const response = dummy
    const response = await getDashboardAppointmentsChartData(payload);
    ReactDOM.unstable_batchedUpdates(() => {
      setAppointmentsChartData(response?.graphdata_hospital_appointment);
      setPractitionerAppointmentsChartData(
        response?.graphdata_practitioners_appointment
      );
      setProcedureRequestsChartData(response?.procedureRequests);
      setPackageAppointmentsChartData(response?.graphdata_package_appointment);
      setRevenueChartData(response?.graphdata_revenue);
      // setFilteredPackageAppointmentsChartData(
      //   response?.graphdata_package_appointment
      // );
    });
  };

  useEffect(() => {
    FetchDashboardcounts();
    FetchTodayAppointments();
    FetchTodayProcedureRequests();
    FetchTodayPackageAppointments();
    setHeading("Dashboard");
  }, [ClinicRefreshPage]);

  useEffect(() => {
    FetchChartData();
  }, [ChartDateRange, ClinicRefreshPage]); // eslint-disable-line

  useEffect(() => {
    FetchDashboardcounts();
  }, [FilterDateRange, ClinicRefreshPage]);

  return (
    <>
      <div className="content">
      <Row>
          <Col md={12} style={{ textAlign: "right" }}>
            <p>
            Get appointments and revenue earned within this dates.
            </p>
            <RangePicker
              dropdownClassName="range-dropdown"
              className="shadow-picker"
              style={{
                width: isMobile ? "100%" : "450px",
                marginBottom: "15px",
              }}
              value={FilterDateRange}
              ranges={DateRanges()}
              format={DATE_FORMAT}
              onChange={(e) => onFilterDateChange(e)}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="6" sm="6">
            <CardMetrics
              CountsLoading={CountsLoading}
              heading="Active Practitioners"
              stat={CardCounts?.practitioners}
              icon={MyPractitionersIcon}
              route="practitioners"
            />
          </Col>
          <Col lg="3" md="6" sm="6">
            {" "}
            <CardMetrics
              CountsLoading={CountsLoading}
              heading="Patients"
              stat={CardCounts.patients}
              icon={PatientsIcon}
              route="patients"
            />
          </Col>
          <Col lg="3" md="6" sm="6">
            <CardMetrics
              CountsLoading={CountsLoading}
              isAppointments={true}
              heading="Medical Procedures"
              stat={CardCounts?.procedures}
              icon={ProcedureIcon}
              route="medical-procedures"
            />
          </Col>
          <Col lg="3" md="6" sm="6">
            <CardMetrics
              CountsLoading={CountsLoading}
              isAppointments={true}
              heading="Health Packages"
              stat={CardCounts?.healthPackages}
              icon={PackagesIcon}
              route="hospital-packages"
            />
          </Col>
          <Col lg="3" md="6" sm="6">
            <CardMetrics
              CountsLoading={CountsLoading}
              isAppointments={true}
              heading="Practitioner Appointments"
              stat={CardCounts?.practitionerAppointments}
              icon={AppointmentsIcon}
              route="appointments"
            />
          </Col>
          <Col lg="3" md="6" sm="6">
            <CardMetrics
              CountsLoading={CountsLoading}
              heading="Procedure Requests"
              stat={CardCounts?.procedureRequests}
              icon={ProcedureRequestsIcon}
              route="procedure-requests"
            />
          </Col>
          <Col lg="3" md="6" sm="6">
            <CardMetrics
              CountsLoading={CountsLoading}
              isAppointments={true}
              heading="Total Package Appointments"
              stat={CardCounts?.packageAppointments}
              icon={PackagesAppointmentsIcon}
              route="package-appointments"
            />
          </Col>
          <Col lg="3" md="6" sm="6">
            <CardMetrics
              CountsLoading={CountsLoading}
              isAppointments={true}
              heading="Online Package Appointments"
              stat={
                CardCounts?.onlinePackageAppointments === null
                  ? 0
                  : (CardCounts?.onlinePackageAppointments && CardCounts?.onlinePackageAppointments?.toLocaleString("en"))
              }
              icon={PackagesAppointmentsIcon}
              route="package-appointments"
            />
          </Col>
          <Col lg="3" md="6" sm="6">
            <CardMetrics
              CountsLoading={CountsLoading}
              isAppointments={true}
              heading="Online collection for Practitioner appointments"
              stat={
                CardCounts?.revenue === null
                  ? 0
                  : (CardCounts?.revenue && CardCounts?.revenue?.toLocaleString("en")) + dirham
              }
              icon={OnlinePaymentsIcon}
              route="online-payments"
            />
          </Col>
          <Col lg="3" md="6" sm="6">
            <CardMetrics
              CountsLoading={CountsLoading}
              isAppointments={true}
              isCta={false}
              heading="Online collection for Package appointments"
              stat={
                CardCounts?.onlinePackageRevenue === null
                  ? 0
                  : (CardCounts?.onlinePackageRevenue && CardCounts?.onlinePackageRevenue?.toLocaleString("en")) + dirham
              }
              icon={OnlinePaymentsIcon}
              // route="online-payments"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={4}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Today's Practitioner Appointments</CardTitle>
              <span className="card-header-count">
                {TodaysAppointments?.total_count}
              </span>
              <CardBody style={{ padding: "0px" }}>
                <TodayAppointments
                  TodaysAppointments={TodaysAppointments?.appointments}
                  FetchTodayAppointments={FetchTodayAppointments}
                  TodayAppointmentsLoading={TodayAppointmentsLoading}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md={6} lg={4}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Today's Package Appointments</CardTitle>
              <span className="card-header-count">
                {TodaysPackageAppointments?.total_count}
              </span>
              <CardBody style={{ padding: "0px" }}>
                <TodayPackageAppointments
                  TodaysAppointments={TodaysPackageAppointments?.appointments}
                  FetchTodayAppointments={FetchTodayPackageAppointments}
                  TodayAppointmentsLoading={TodaysPackageAppointmentsLoading}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md={6} lg={4}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Today's Procedure Requests</CardTitle>
              <span className="card-header-count">
                {TodaysProcedureRequests?.total_count}
              </span>
              <CardBody style={{ padding: "0px" }}>
                <TodayProcedureRequests
                  TodaysProcedureRequests={TodaysProcedureRequests}
                  TodayProcedureRequestsLoading={TodayProcedureRequestsLoading}
                />
              </CardBody>
            </Card>
          </Col>
          {/* <Col md={12} lg={4}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Appointments by Status</CardTitle>
              <CardBody style={{ padding: "0px" }}>
                <AppointmentStatusChart
                  AppointmentsByStatus={AppointmentsByStatus}
                />
              </CardBody>
            </Card>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Appointments by Type</CardTitle>
              <CardBody style={{ padding: "0px" }}>
                <AppointmentsTypeChart
                  AppointmentsByTypes={AppointmentsByTypes}
                />
              </CardBody>
            </Card>
          </Col> */}
        </Row>
        <Row>
          <Col md={12} lg={6}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Appointments by Status</CardTitle>
              <CardBody style={{ padding: "0px" }}>
                <AppointmentStatusChart
                  AppointmentsByStatus={AppointmentsByStatus}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={6}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Appointments by Type</CardTitle>
              <CardBody style={{ padding: "0px" }}>
                <AppointmentsTypeChart
                  AppointmentsByTypes={AppointmentsByTypes}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ textAlign: "right" }}>
            <p>
              Get performance charts from these days.
            </p>
            <RangePicker
              dropdownClassName="range-dropdown"
              className="shadow-picker"
              style={{
                width: isMobile ? "100%" : "450px",
                marginBottom: "15px",
              }}
              value={ChartDateRange}
              ranges={DateRanges()}
              format={DATE_FORMAT}
              onChange={(e) => onChangeDate(e)}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Col>
          <Col md={12} lg={6}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Appointments</CardTitle>
              <CardBody style={{ padding: "0px" }}>
                {AppointmentsChartData && (
                  <AppointmentsChart
                    AppointmentsChartData={AppointmentsChartData}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={6}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Procedure Requests</CardTitle>
              <CardBody style={{ padding: "0px" }}>
                {ProcedureRequestsChartData && (
                  <ProcedureRequestsChart
                    ProcedureRequestsChartData={
                      ProcedureRequestsChartData
                        ? ProcedureRequestsChartData
                        : []
                    }
                  />
                )}
              </CardBody>
            </Card>
          </Col>

          <Col md={12} lg={12}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Practitioner Appointments</CardTitle>
              <CardBody style={{ padding: "0px" }}>
                <PractitionerAppointmentsChart
                  PractitionerAppointmentsChartData={
                    PractitionerAppointmentsChartData
                  }
                />
              </CardBody>
            </Card>
          </Col>
          {/* <Col md={12} style={{ textAlign: "right" }}>
            <Select
              mode="multiple"
              optionFilterProp="children"
              size="large"
              className="shadow-picker range-dropdown"
              placeholder="Please select"
              style={{
                width: isMobile ? "100%" : "450px",
                marginBottom: "15px",
              }}
              onChange={(values) => setSelectedPackages(values)}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {(PackageAppointmentsChartData?.packageArray || []).map(
                (item) => (
                  <Select.Option key={item}>{item}</Select.Option>
                )
              )}
            </Select> */}
          {/* <RangePicker
              dropdownClassName="range-dropdown"
              className="shadow-picker"
              style={{
                width: isMobile ? "100%" : "450px",
                marginBottom: "15px",
              }}
              value={ChartDateRange}
              ranges={DateRanges()}
              format={DATE_FORMAT}
              onChange={(e) => onChangeDate(e)}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            /> */}
          {/* </Col> */}

          <Col md={12} lg={12}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Revenue Earned</CardTitle>
              <CardBody style={{ padding: "0px" }}>
              {RevenueChartData && (
                  <RevenuesChart
                    RevenuesChartData={RevenueChartData}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={12}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Package Appointments</CardTitle>
              <CardBody style={{ padding: "0px" }}>
                <PackageAppointmentsChart data={PackageAppointmentsChartData} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;

// import React,{ useEffect } from "react";

// const Hell = () => {

//   useEffect(() => {
//     debugger
//   }, []);

//   return <div>hhh</div>;
// };

// export default Hell;
