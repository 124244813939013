import React, { useState, createContext, useContext } from "react";
import {
  postAppointmentCheckout,
  postAppointmentSuccess,
  postAddPatientByInstantBooking,
} from "Utils/Services/Practitioner/QuickBooking";
import { modelAppointmentCheckoutApiPayload } from "models/practitioner/quickbooking.api.models";
import moment from "moment";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

export const QuickBookingContext = createContext();

export const QuickBookingContextProvider = (props) => {
  const [mobileNumber, setMobileNumber] = useState({
    mobile: "",
    country: "ae",
  });
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [isPatientExist, setIsPatientExist] = useState(null);
  const [selectedAppointmentType, setSelectedAppointmentType] = useState(null);

  const addBooking = async (payload) => {
    setIsBtnLoading(true);
    try {
      const response = await postAppointmentCheckout(
        modelAppointmentCheckoutApiPayload(payload)
      );
      if (!response?.status) throw new Error("Something went wrong");
      const successResponse = await postAppointmentSuccess({
        order_id: response?.data?.order_id || "",
        existing: payload?.existing || 1,
        password: payload?.password || null,
      });
      if (!successResponse?.status) {
        setIsBtnLoading(false);
        throw new Error("Something went wrong");
      };
      //message.success("Booking Successful");
      setIsBtnLoading(false);
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Booking Successful"
      });
    } catch {
      //message.error("Something went wrong");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Something went wrong"
      });
      setIsBtnLoading(false);
    } finally {
      //clear data
      setIsBtnLoading(false);
      setPatientData(null);
      setSelectedHospital(null);
      setAppointmentDate(null);
      setIsPatientExist(null);
    }
  };

  const addBookingWithNewPatient = async (values) => {
    let { first_name = "", last_name = "", dob = "", gender = "" } = values;
    setIsBtnLoading(true);
    const addPatientResponse = await postAddPatientByInstantBooking({
      first_name,
      last_name,
      dob: moment(dob).format("YYYY-MM-DD"),
      gender,
      dialercode: mobileNumber.country || 91,
      mobile: mobileNumber.mobile.substring(mobileNumber.country.length) || "",
      notify: 1,
    });

    if (addPatientResponse && addPatientResponse?.status) {
      await addBooking({
        patient_id: addPatientResponse?.data?.patient_id,
        clinic_id: selectedHospital || "",
        appointment_date: appointmentDate?.date,
        appointment_time: appointmentDate?.time,
        booking_type_id: selectedAppointmentType || 1,
        existing: 0,
        password: addPatientResponse?.data?.password || "",
      });
    } else {
      //message.error("Something went wrong");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Something went wrong"
      });
      setIsBtnLoading(false);
    }
  };

  const values = {
    mobileNumber,
    setMobileNumber,
    isBtnLoading,
    setIsBtnLoading,
    patientData,
    setPatientData,
    selectedHospital,
    setSelectedHospital,
    appointmentDate,
    setAppointmentDate,
    isPatientExist,
    setIsPatientExist,
    addBooking,
    addBookingWithNewPatient,
    selectedAppointmentType,
    setSelectedAppointmentType,
  };

  return <QuickBookingContext.Provider value={values} {...props} />;
};

export const useQuickBooking = () => {
  const context = useContext(QuickBookingContext);
  if (context === undefined)
    throw new Error(`must be used within a QuickBookingContextProvider.`);
  return context;
};
