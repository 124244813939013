import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import LocationMap from "components/LocationMap";
import React, { useEffect, useState} from "react";

import {
  GetHospitalLocationDetails,
  PostHospitalLocationDetails,
} from "Utils/Services/Hospital/HospitalProfile";
import { checkOrAddArea, checkOrAddStreet, getAreasOfEmirate } from "Utils/Services/Onboarding";
import { validateMessages } from "Utils/validations";

const LocationDetails = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [areas, setAreas] = useState([]);
  const [streets, setStreets] = useState([]);
  const [form] = Form.useForm();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  const handleStreetSearch = (value) => {
    const exist = data.streets?.find((street)=>street?.street === value);
    !exist && setStreets([...data.streets,{
        id: value,
        value: value,
        label: value,
        street: value
    }]);
  };

  const handleAreaSearch = (value) => {
    const exist = data.areas?.find((area)=>area?.area === value);
    !exist && setAreas([...data.areas,{
        id: value,
        value: value,
        label: value,
        area: value
    }]);
  };
  const handleEmirateChange = async (value) => {
    form.setFieldsValue({
      area_id: "",
    });
    const areasResponse = await getAreasOfEmirate({emirate_id:value});
    if(areasResponse?.status){
      const emirates = areasResponse?.data?.map(eachArea => ({
          value: eachArea?.id.toString(),
          id: eachArea?.id.toString(),
          label: eachArea?.area,
          area: eachArea?.area,
      }));
      setAreas([...emirates]);
    };
  };

  const fetchData = async () => {
    const response = await GetHospitalLocationDetails();
    response && setData(response);
    response && response?.areas?.length && setAreas(response?.areas);
    response && response?.streets?.length && setStreets(response?.streets);
    response?.location_info[0]?.latitude && setLat(response.location_info[0].latitude?.replace(/[^0-9.-]/g, ''));
    response?.location_info[0]?.longitude && setLng(response.location_info[0].longitude?.replace(/[^0-9.-]/g, ''));
    response && form.setFieldsValue(response.location_info[0]);
  };
  
  const getGeoLocation = ()=>{
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position)=>{
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          form.setFieldsValue({
            ...form.getFieldsValue(),
            latitude:position.coords.latitude,
            longitude:position.coords.longitude
          });
        },
        (error)=>{
          if(error.code === 1){
            AntdCustomMessage({
              type : antdMessageTypes.ERROR,
              content: "Please allow ShopDoc to access your location.",
              style: {
                marginTop: "100px",
              },
              closeable : true,
            })
          }else{
            AntdCustomMessage({
              type : antdMessageTypes.ERROR,
              content: "Unable to fetch your location.",
              style: {
                marginTop: "100px",
              },
              closeable : true,
            })
          }
          // console.log(error)
        },
        { enableHighAccuracy: true }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const showGeoLocation = ()=>{
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position)=>{
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        (error)=>{
          // console.log(error)
        },
        { enableHighAccuracy: true }
      );
      
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    form.setFieldsValue({[name]:value});
    if(name==="latitude"){
      setLat(value?.replace(/[^0-9.-]/g, ''));
    }else{
      setLng(value?.replace(/[^0-9.-]/g, ''));
    }
    if(!e.target.value){
      showGeoLocation();
    }
  };

  const handleMapClick = ({lat,lng}) => {
    setLat(lat);
    setLng(lng);
    form.setFieldsValue({...form.getFieldsValue(),latitude:lat,longitude:lng});
  };

  const validateLatitude = (_, value) => {
    const longitude = form.getFieldValue('longitude');
    if (!value) {
      return longitude ? Promise.reject('Latitude is required'): Promise.resolve();
    }
    return Promise.resolve();
  };

  const validateLongitude = (_, value) => {
    const latitude = form.getFieldValue('latitude');
    if (!value) {
      return latitude ? Promise.reject('Longitude is required'): Promise.resolve();
    }
    return Promise.resolve();
  };

  const onFinish = async (values) => {
    const street = data?.streets?.find(street =>street.id === values.street_id);
    const area = data?.areas?.find(area=>area.id === values.area_id);
    let streetId = values.street_id;
    let areaId = values.area_id;
    if(street){
      streetId = street.id;
    }else{
      const streetResponse = await checkOrAddStreet({street: values.street_id});
      streetId = streetResponse?.streetId || values?.street_id;
    }
    if(area){
      areaId = area.id;
    }else{
      const areaResponse = await checkOrAddArea({
        areaName: values.area_id,
        emirate_id: values.emirate_id
      });
      areaId = areaResponse?.areaId || values?.area_id;
    }
    setIsLoading(true);
    const response = await PostHospitalLocationDetails({
      ...values,
      street_id:streetId, 
      area_id:areaId
    });
    if (response?.status === 200) {
      AntdCustomMessage({
        type : antdMessageTypes.SUCCESS,
        content: "Succesfully Updated.",
        style: {
          marginTop: "100px",
        },
        closeable : true,
      });
    }
    //window.location.reload();
    fetchData();
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    const formValues = form.getFieldsValue();
    if(!formValues?.latitude && !formValues?.longitude){
      showGeoLocation();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data?.location_info ? (
    <Card>
      <Form
        form={form}
        layout="vertical"
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={data?.location_info[0]}
      >
        {/* row starts here */}
        <Row gutter={{ sm: 8, lg: 24 }}>
        <Col xs={24} sm={24} lg={12}>
          <Form.Item
          label="Office Number"
          name="office_number"
          rules={[
              {
              required: true,
              message: 'Please input office number!',
              },
              ({ getFieldValue }) => ({
                  validator(_, value) {
                      if (value && !/^\d*$/.test(value)) {
                        return Promise.reject('Please enter only digits');
                      }
                      if (value.length < 6 || value.length > 15) {
                        return Promise.reject('Office number must be between 6 and 15 digits');
                      }
                      return Promise.resolve();
                  },
              }),
          ]}
        >
              <Input type="tel" pattern="^[0-9]*$"/>
        </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["address"]}
              label="Address"
              rules={[{ required: true }]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["building"]}
              label="Building"
              rules={[{ required: true }]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["floor"]}
              label="Floor"
              rules={[{ required: true }]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["emirate_id"]}
              label="Emirate"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select an emirate"
                optionFilterProp="children"
                size="large"
                onChange={handleEmirateChange}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {data?.emirates && data?.emirates?.map((item) => (
                  <Select.Option key={item.id} value={item?.id}>{item?.emirate}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["area_id"]}
              label="Area"
              rules={[{ required: true }]}
            >
               <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select area"
                optionFilterProp="children"
                size="large"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                // onSearch={handleAreaSearch}
              >
                {
                  areas.length && areas.map((item) => (
                      <Select.Option 
                        key={item?.id} 
                        value={item?.id}
                      >
                        {item?.area}
                      </Select.Option>
                    ))
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["street_id"]}
              label="Street"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a street"
                optionFilterProp="children"
                size="large"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                // onSearch={handleStreetSearch}
              >
                {
                  streets.length && streets.map((item) => (
                      <Select.Option 
                        key={item?.id} 
                        value={item?.id}
                      >
                        {item?.street}
                      </Select.Option>
                    ))
                }
              </Select>

            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["pincode"]}
              label="Postal Code"
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item 
              name={["latitude"]} 
              label="Latitude"
              rules={[
                { validator: validateLatitude, dependencies: ['longitude'] },
              ]}
            >
              <Input size="large" name="latitude" onChange={handleInputChange}/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item 
              name={["longitude"]} 
              label="Longitude"
              rules={[
                { validator: validateLongitude, dependencies: ['latitude'] },
              ]}
            >
              <Input size="large" name="longitude" onChange={handleInputChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} lg={24}>
            <Button onClick={getGeoLocation}>Click here to fill latitude and longitude from geolocation.</Button>
          </Col>
        </Row>
          
        <Row style={{margin:"5px 0"}}>
          <Col xs={24} sm={24} lg={24}>
          <LocationMap position={{lat,lng}} handleMapClick={handleMapClick}/>
          </Col>
        </Row>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ height: 40 }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  ) : (
    <p>loading..</p>
  );
};

export default LocationDetails;
