import axios from "axios";
import Cookies from "universal-cookie";
import { refreshToken } from "Utils/Services/Auth";
import { DecryptString } from "./Constants";

const axiosApiInstance = axios.create({
  // baseURL: "https://shopdoc.loca.lt",
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "http://localhost:4000",
  timeout: 30 * 1000, // 30 seconds
});

const cookies = new Cookies();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: cookies.get("token", { path: "/" })
        ? cookies.get("token", { path: "/" })
        : "",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const updateLocalAccessToken = async (token) => {
  cookies.set("token", token, {
    path: "/",
  });
};

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalConfig = error.config;
    if (error.response) {
      // Access Token was expired
      if (error.response.status === 401 && !originalConfig._retry) {
        let cookieUserData = DecryptString(
          cookies.get("userdata", { path: "/" })
        );
        let UserData = cookieUserData && JSON.parse(cookieUserData);
        originalConfig._retry = true;

        try {
          if (UserData?.email) {
            const rs = await refreshToken({
              username: UserData?.email,
              userid: UserData?.userid,
              role: UserData?.type,
              refreshToken: UserData?.refreshtoken,
            });
            const { token } = rs.data;
            await updateLocalAccessToken(token);
            return axiosApiInstance(originalConfig);
          } else {
            return (window.location.href = "/login");
          }
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    // const originalRequest = error.config;
    // if (error.response.status === 401 ) {
    //   let cookieUserData = DecryptString(
    //     cookies.get("userdata", { path: "/" })
    //   );
    //   let UserData = cookieUserData && JSON.parse(cookieUserData);
    
    //   originalRequest._retry = true;
    //   if (UserData?.email) {
    //     axios
    //       .post(`${process.env.REACT_APP_BASE_URL}/auth/token`, {
    //         username: UserData?.email,
    //         userid: UserData?.userid,
    //         refreshToken: UserData?.refreshtoken,
    //       })
    //       .then((resp) => {
    //         if (resp?.token) {
    //           axios.defaults.headers.common["Authorization"] =
    //             resp?.token || "";
    //           return axiosApiInstance(originalRequest);
    //         }
    //       })
    //       .catch((err) => {
    //         console.log("refresh token error", err);
    //       });
    // refreshToken({
    //   username: UserData?.email,
    //   userid: UserData?.userid,
    //   refreshToken: UserData?.refreshtoken,
    // })
    //   .then((res) => {
    //     axios.defaults.headers.common["Authorization"] = res?.token || "";
    //     return axiosApiInstance(originalRequest);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // }
    // return (window.location.href = "/login");
    // } else {
    //   return Promise.reject(error);
    // }
  }
);

export default axiosApiInstance;
