import axios from "axios";
import axiosApiInstance from "Utils/ApiInstance";

export const getLanguagesList = async () => {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/admin/language_spoken_list`,
          );
        if(response?.status === 200) {
            result.status = true;
            result.data = [...response.data];
        }
    }catch{
        result.status = false;
        result.data = [];
        result.message = "Something went wrong."
    }finally{
        return result;
    } 
};

export const getPractitionerTypeList = async () => {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/admin/practitioner_types`,
          );
        if(response?.status === 200) {
            result.status = true;
            result.data = [...response.data.data];
        }
    }catch{
        result.status = false;
        result.data = [];
        result.message = "Something went wrong."
    }finally{
        return result;
    } 
};
export const getServicesList = async () => {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/admin/serviceslists
            `,
          );
        if(response?.status === 200) {
            result.status = true;
            result.data = [...response.data];
        }
    }catch{
        result.status = false;
        result.data = [];
        result.message = "Something went wrong."
    }finally{
        return result;
    } 
};

export const getStatesList = async () => {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/admin/statelist`,
          );
        if(response?.status === 200) {
            result.status = true;
            result.data = [...response.data];
        }
    }catch{
        result.status = false;
        result.data = [];
        result.message = "Something went wrong."
    }finally{
        return result;
    } 
};

export const getDistrictsList = async () => {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/admin/districtlist`,
          );
        if(response?.status === 200) {
            result.status = true;
            result.data = [...response.data];
        }
    }catch{
        result.status = false;
        result.data = [];
        result.message = "Something went wrong."
    }finally{
        return result;
    } 
};

export const getPlacesList = async () => {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/admin/getPlaces`,
          );
        if(response?.status === 200) {
            result.status = true;
            result.data = [...response.data];
        }
    }catch{
        result.status = false;
        result.data = [];
        result.message = "Something went wrong."
    }finally{
        return result;
    } 
};

export const getDistrictsOfState = async (values) => {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/admin/DistrictBystate/${values.state_id}`,
          );
        if(response?.status === 200) {
            result.status = true;
            result.data = [...response.data];
        }
    }catch{
        result.status = false;
        result.data = [];
        result.message = "Something went wrong."
    }finally{
        return result;
    } 
};

export const getPlacesOfDistrict = async (values) => {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/admin/placesinfo/${values.district_id}`,
          );
        if(response?.status === 200) {
            result.status = true;
            result.data = [...response.data];
        }
    }catch{
        result.status = false;
        result.data = [];
        result.message = "Something went wrong."
    }finally{
        return result;
    } 
};

export const getPincodeOfPlace = async (values) => {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/common/getPincodeByPlace/${values.place}`,
          );
        if(response?.status === 200) {
            result.status = true;
            result.data = [...response.data];
        }
    }catch{
        result.status = false;
        result.data = [];
        result.message = "Something went wrong."
    }finally{
        return result;
    } 
}

export const getDepartmentsList = async () => {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/admin/departmentlists`,
          );
        if(response?.status === 200) {
            result.status = true;
            result.data = [...response.data];
        }
    }catch{
        result.status = false;
        result.data = [];
        result.message = "Something went wrong."
    }finally{
        return result;
    } 
};

export async function updatePractitionerOnboardingProfile(values) {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        const response = await axiosApiInstance
            .put(
                '/api/v1/practitioner/profile',
                values
            );
        if(response?.status === 200) {
            result.status = true;
            result.data = response.data;
        }
    }catch(e){
        result.status = false;
        result.data = {};
        result.message = e?.message || e?.error || "Something went wrong."
    }finally{
        return result;
    };
};

export async function updateHospitalOnboardingProfile(values) {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        const response = await axiosApiInstance
            .put(
                '/api/v1/hospital/profile',
                values
            );
        if(response?.status === 200) {
            result.status = true;
            result.data = response.data;
        }
    }catch(e){
        result.status = false;
        result.data = {};
        result.message = e?.message || e?.error || "Something went wrong."
    }finally{
        return result;
    };
};

export async function updateLabOnboardingProfile(values) {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        const response = await axiosApiInstance
            .put(
                '/api/v1/lab/profile',
                values
            );
        if(response?.status === 200) {
            result.status = true;
            result.data = response.data;
        }
    }catch(e){
        result.status = false;
        result.data = {};
        result.message = e?.message || e?.error || "Something went wrong."
    }finally{
        return result;
    };
};


export const getEmiratesList = async () => {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/admin/emiratelist`,
          );
        if(response?.status === 200) {
            result.status = true;
            result.data = [...response.data];
        }
    }catch{
        result.status = false;
        result.data = [];
        result.message = "Something went wrong."
    }finally{
        return result;
    } 
};

export const getAreasList = async () => {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/admin/arealist`,
          );
        if(response?.status === 200) {
            result.status = true;
            result.data = [...response.data];
        }
    }catch{
        result.status = false;
        result.data = [];
        result.message = "Something went wrong."
    }finally{
        return result;
    } 
};

export const getStreetsList = async () => {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/admin/streetlist`,
          );
        if(response?.status === 200) {
            result.status = true;
            result.data = [...response.data];
        }
    }catch{
        result.status = false;
        result.data = [];
        result.message = "Something went wrong."
    }finally{
        return result;
    } 
};

export const getAreasOfEmirate = async (values) => {
    let result = {
        status: false,
        data: [],
        message : "",
    }
    try{
        let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/admin/areaByEmirate/${values.emirate_id}`,
          );
        if(response?.status === 200) {
            result.status = true;
            result.data = [...response.data];
        }
    }catch{
        result.status = false;
        result.data = [];
        result.message = "Something went wrong."
    }finally{
        return result;
    } 
};

export const checkOrAddStreet = async (payload) => {
    let result = {}
    try{
        let response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/common/checkOrAddStreet`,{
                streetName: payload.street
            }
        );
        if(response?.status === 200) {
            result = response.data;
        }
    }catch{
        result = {};
    }finally{
        return result;
    } 
};

export const checkOrAddArea = async (payload) => {
    let result = {}
    try{
        let response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/common/checkOrAddArea`,{
                "areaName": payload.areaName,
                "emirate_id": payload.emirate_id
              }
        );
        if(response?.status === 200) {
            result = response.data;
        }
    }catch{
        result = {};
    }finally{
        return result;
    } 
};