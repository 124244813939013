import { Button, Dropdown, Menu, Popconfirm, Space, Spin } from "antd";
import { GlobalState } from "context-api";
import moment from "moment";
import React, { useContext } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ChangeAppointmentStatus } from "./Services/Hospital/Appointments";
import CryptoJS from "crypto-js";
import { useHistory } from "react-router-dom";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

// const Now = moment(new Date()).format("YYYY-MM-DD H:m:s");

export const TableSkeletonLoader = (count, height) => {
  return (
    <SkeletonTheme
    // color="#0fa0cf14" highlightColor="#e9f0f399"
    >
      <Skeleton count={count} duration={1} height={height} />
    </SkeletonTheme>
  );
};

export const TotalSpinner = () => {
  const { TotalLoading } = useContext(GlobalState);
  return (
    <>
      {TotalLoading && (
        <div className="total-spinner-backdrop">
          <Spin size="large" className="total-spinner" />
        </div>
      )}
    </>
  );
};

export const getDateFormat = (date) => {
  // 2021-12-31 12:03:52 (utc)- 31 Dec 2021, 4:03 PM(local)
  // const DateUtc = moment().utc(date);
  // const localDate = moment(DateUtc).local();
  // return moment(localDate).format("DD MMM YYYY");
  return moment.utc(date).local().format("DD MMM YYYY");
};

export const getDateTimeFormat = (date) => {
  // 2021-12-31 12:03:52 (utc)- 31 Dec 2021, 4:03 PM(local)
  // const DateUtc = moment(date).utc();
  // const localDate = moment(DateUtc).local();
  // return moment(localDate).format("DD MMM YYYY, h:mm A");

  return moment.utc(date).local().format("DD MMM YYYY, h:mm A")
};

export const getTimeFormat = (date) => {
  // 2021-12-31 12:03:52 (utc)- 4:03 PM(local)
  
  // const DateUtc = moment().utc(date);
  // const localDate = moment(DateUtc).local();
  // return moment(localDate).format("h:mm A");
  return moment.utc(date).local().format("h:mm A");
};

export const getDateTimeFormatLocal = (date) => {
  return moment(date).format("DD MMM YYYY, h:mm A");
};
export const getTimeFormatLocal = (date) => {
  return moment(date).format("h:mm A");
};

export const getAppointmentDate = (date) => {
  // 2022-01-01 (utc)- 01 Jan 2022(local)
  var a = moment(date, "YYYY-MM-DD");

  let b = a.format("DD MMM YYYY");
  if (moment(a).isSame(moment(), "day")) {
    b = "Today";
  }
  return b;
};

export const expireCounter = () => {};

export const getNotificationFromNow = (date) => {
  const testDateUtc = moment.utc(date);
  const localDate = moment(testDateUtc).local();
  const localCreatedAt = localDate.fromNow();
  return localCreatedAt;
};

export const getExpiryCounter = (date1, date2) => {
  if (!date2) {
    date2 = moment.utc();
  }
  const testDateUtc = moment.utc(date1);
  const localDate = moment(testDateUtc).local();
  let diff = localDate.diff(date2, "days");
  let string = "days";
  if (diff === 0) {
    diff = localDate.diff(date2, "hours");
    string = "hours";
  }

  if (diff === 0) {
    diff = localDate.diff(date2, "minutes");
    string = "minutes";
  }
  if (diff === 0) {
    diff = localDate.diff(date2, "seconds");
    string = "seconds";
  }
  if (diff < 0) {
    return "Expired";
  }

  return diff + " " + string + " to expire";
};

export const getNotificationType = (type) => {
  if (type === "practiceRequest") {
    return "Practice Requests";
  } else if (type === "appointmentBooking") {
    return "Appointments";
  } else if (type === "procedureRequest") {
    return "Procedure Requests";
  } else if (type === "packageAppointmentBooking") {
    return "Package Appointments";
  } else {
    return type;
  }
};

export const AppointmentStatusFormatter = (status) => {
  if (status === "notresponded") {
    return <span className="app_status notresponded">Not Responded</span>;
  } else if (status === "notattended") {
    return <span className="app_status notattended">Not Attended</span>;
  } else if (status === "completed") {
    return <span className="app_status completed">Completed</span>;
  } else if (status === "check-in") {
    return <span className="app_status checkedin">Checked In</span>;
  } else if (status === "check-out") {
    return <span className="app_status checkedout">Checked Out</span>;
  } else if (status === "pending") {
    return <span className="app_status pending">Pending</span>;
  } else if (status === "cancelled") {
    return <span className="app_status cancelled">Cancelled</span>;
  } else if (status === "approved") {
    return <span className="app_status approved">Confirmed</span>;
  } else if (status === "rescheduled") {
    return <span className="app_status rescheduled">Rescheduled</span>;
  } else if (status === "videoconference") {
    return <span className="app_status videoconference">Video Conference</span>;
  } else {
    return status;
  }
};

export const ActionStatusFormatter = (status) => {
  if (status === "requested") {
    return (
      <Button className="action_status requested">Waiting for confirmation</Button>
    );
  } else if (status === "accept") {
    return <span className="action_status accept">Accept</span>;
  } else if (status === "rejected") {
    return <Button className="action_status reject">Rejected</Button>;
  } else if (status === "send_request") {
    return <span className="action_status checkedin">Send Request</span>;
  } else {
    return status;
  }
};

export const PaymentFormatter = (status) => {
  if (status === "0") {
    return "Not Completed";
  } else if (status === "1") {
    return "Success";
  } else {
    return "Not Applicable";
  }
};

export const SetAppointmentButtons = (
  row,
  handleViewClick,
  FetchData,
  setShowRescheduleModal,
  setSelectedPractitionerId,
  setSelectedAppointmentId
) => {
  const set_data = (needed_status, id) => {
    var data = {
      id: id,
      needed_status: needed_status,
    };

    ChangeAppointmentStatus(data).then((response) => {
      if (response && response.affectedRows > 0) {
        //message.success("Action completed successfully");
        AntdCustomMessage({
          content : "Action completed successfully",
          type : antdMessageTypes.SUCCESS,
        });
        FetchData();
      } else {
        //message.warning("Something went wrong");
        AntdCustomMessage({
          content : "Something went wrong",
          type : antdMessageTypes.WARNING,
        });
      }
    });
  };
  const PendingActionMenu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          set_data("approved", row.appointment_id);
        }}
      >
        Approve
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          set_data("cancelled", row.appointment_id);
        }}
      >
        Cancel
      </Menu.Item>
    </Menu>
  );
  const ApprovedActionMenu = (
    <>
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => {
            set_data("check-in", row.appointment_id);
          }}
        >
          Check-in
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => {
            set_data("cancelled", row.appointment_id);
          }}
        >
          Cancel
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => {
            setSelectedPractitionerId(row.practitioner_id);
            setSelectedAppointmentId(row.appointment_id);
            setShowRescheduleModal(true);
          }}
        >
          Reschedule
        </Menu.Item>
      </Menu>
    </>
  );
  const CheckInActionMenu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          set_data("cancelled", row.appointment_id);
        }}
      >
        Cancel
      </Menu.Item>
    </Menu>
  );
  if (row.appointment_status === "check-in") {
    return (
      <Space size="middle">
        <Button onClick={() => handleViewClick(row)}>View</Button>
        <Dropdown.Button overlay={CheckInActionMenu}>More</Dropdown.Button>
      </Space>
    );
  } else if (row.appointment_status === "pending") {
    return (
      <Space size="middle">
        <Button onClick={() => handleViewClick(row)}>View</Button>
        <Dropdown.Button overlay={PendingActionMenu}>More</Dropdown.Button>
      </Space>
    );
  } else if (row.appointment_status === "approved") {
    return (
      <Space size="middle">
        <Button onClick={() => handleViewClick(row)}>View</Button>
        <Dropdown.Button overlay={ApprovedActionMenu}>More</Dropdown.Button>
      </Space>
    );
  } else if (row.appointment_status === "rescheduled") {
    return (
      <Space size="middle">
        <Button onClick={() => handleViewClick(row)}>View</Button>
      </Space>
    );
  } else if (row.appointment_status === "videoconference") {
    return <span className="app_status videoconference">Video Conference</span>;
  } else {
    return <Button onClick={() => handleViewClick(row)}>View</Button>;
  }
};

export const SetLabCardAppointmentButtons = (
  row,
  history,
  changeStatus,
  setShowCheckInModal
) => {
  if (row.appointment_status === "pending") {
    return (
      <div className="appointment-action-buttons">
        <Button
          className="approve"
          onClick={() => {
            changeStatus("approved");
          }}
        >
          Approve
        </Button>
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to cancel?"
          onConfirm={() => {
            changeStatus("cancelled");
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button className="cancel">Cancel</Button>
        </Popconfirm>
      </div>
    );
  } else if (row.appointment_status === "approved") {
    return (
      <div className="appointment-action-buttons">
        <Button
          className="checkin"
          onClick={() => {
            setShowCheckInModal(true);
          }}
        >
          Check In
        </Button>
      </div>
    );
  } else if (row.appointment_status === "check-in") {
    return (
      <div className="appointment-action-buttons">
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to checkout?"
          onConfirm={() => {
            changeStatus("completed");
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button className="checkout">Checkout</Button>
        </Popconfirm>
      </div>
    );
  } else if (row.appointment_status === "completed") {
    return (
      <div className="appointment-action-buttons">
        <Button
          className="reports"
          onClick={() =>
            history.push("/lab/appointments/test-reports/" + row.appointment_id)
          }
        >
          Test Reports
        </Button>
      </div>
    );
  } else {
    return (
      <div className="appointment-action-buttons">
        <Button>No Actions</Button>
      </div>
    );
  }
};

export const SetCardAppointmentButtons = (
  row,
  FetchData,
  setShowRescheduleModal,
  setSelectedPractitionerId,
  setSelectedAppointmentId,
  today
) => {
  let history = useHistory();
  const set_data = (needed_status, id) => {
    var data = {
      id: id,
      needed_status: needed_status,
    };

    ChangeAppointmentStatus(data).then((response) => {
      if (response.status) {
        // message.success(
        //   `Appointment ${
        //     needed_status === "check-in" ? "checked in" : needed_status
        //   } successfully`
        // );
        AntdCustomMessage({
          content : `Appointment ${
            needed_status === "check-in" ? "checked in" : needed_status
          } successfully`,
          type : antdMessageTypes.SUCCESS,
        });
        FetchData();
      } else {
        //message.warning("Something went wrong");
        AntdCustomMessage({
          content : "Something went wrong",
          type : antdMessageTypes.WARNING,
        });
      }
    });
  };

  if (row.appointment_status === "pending") {
    return (
      <div className="appointment-action-buttons">
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to confirm?"
          onConfirm={() => {
            set_data("approved", row.appointment_id);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button className="approve">Confirm</Button>
        </Popconfirm>
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to cancel?"
          onConfirm={() => {
            set_data("cancelled", row.appointment_id);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button className="cancel">Cancel</Button>
        </Popconfirm>
      </div>
    );
  } else if (row.appointment_status === "approved") {
    return (
      <div className="appointment-action-buttons">
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to reschedule?"
          onConfirm={() => {
            setSelectedPractitionerId(row.practitioner_id);
            setSelectedAppointmentId(row.appointment_id);
            setShowRescheduleModal(true);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button className="reschedule">Reschedule</Button>
        </Popconfirm>

        <Popconfirm
          placement="topRight"
          title="Are you sure you want to cancel?"
          onConfirm={() => {
            set_data("cancelled", row.appointment_id);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button className="cancel">Cancel</Button>
        </Popconfirm>
        {row.auto_checkin_status === 1 &&
        row?.appointment_types !== "Video consultation" &&
        row?.appointment_types !== "Phone consultation" ? (
          <Popconfirm
            placement="topRight"
            title="Are you sure you want to checkin?"
            onConfirm={() => {
              set_data("check-in", row.appointment_id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button className="checkin">Check In</Button>
          </Popconfirm>
        ) : (
          <>
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to complete?"
              onConfirm={() => {
                set_data("completed", row.appointment_id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button className="checkin">Complete</Button>
            </Popconfirm>
            <Button
              onClick={() => {
                history.push(
                  `/hospital/reports/${EncriptString(
                    row.appointment_id.toString()
                  )}`
                );
              }}
            >
              Reports
            </Button>
          </>
        )}
      </div>
    );
  } else if (row.appointment_status === "check-in") {
    return (
      <div className="appointment-action-buttons">
        <Button
          onClick={() => {
            history.push(
              `/hospital/reports/${EncriptString(
                row.appointment_id.toString()
              )}`
            );
          }}
        >
          Reports
        </Button>
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to checkout?"
          onConfirm={() => {
            set_data("completed", row.appointment_id);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button className="approve">Checkout</Button>
        </Popconfirm>
      </div>
    );
  } else if (row.appointment_status === "rescheduled") {
    return (
      <div className="appointment-action-buttons">
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to reschedule?"
          onConfirm={() => {
            setSelectedPractitionerId(row.practitioner_id);
            setSelectedAppointmentId(row.appointment_id);
            setShowRescheduleModal(true);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button className="reschedule">Reschedule</Button>
        </Popconfirm>

        <Popconfirm
          placement="topRight"
          title="Are you sure you want to cancel?"
          onConfirm={() => {
            set_data("cancelled", row.appointment_id);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button className="cancel">Cancel</Button>
        </Popconfirm>
        {row.auto_checkin_status === 1 &&
        row?.appointment_types !== "Video consultation" &&
        row?.appointment_types !== "Phone consultation" ? (
          <Popconfirm
            placement="topRight"
            title="Are you sure you want to checkin?"
            onConfirm={() => {
              set_data("check-in", row.appointment_id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button className="checkin">Check In</Button>
          </Popconfirm>
        ) : (
          <>
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to complete?"
              onConfirm={() => {
                set_data("completed", row.appointment_id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button className="checkin">Complete</Button>
            </Popconfirm>
            <Button
              onClick={() => {
                history.push(
                  `/hospital/reports/${EncriptString(
                    row.appointment_id.toString()
                  )}`
                );
              }}
            >
              Reports
            </Button>
          </>
        )}
      </div>
    );
  } else if (row.appointment_status === "completed") {
    return (
      <div className="appointment-action-buttons">
        <Button
          onClick={() => {
            history.push(
              `/hospital/reports/${EncriptString(
                row.appointment_id.toString()
              )}`
            );
          }}
        >
          Reports
        </Button>
      </div>
    );
  } else {
    if (today) {
      return;
    }
    return (
      <div className="appointment-action-buttons">
        <Button>No Actions</Button>
      </div>
    );
  }
};

export const DATE_FORMAT = "DD MMM YYYY";

export const DateRanges = () => ({
  Today: [moment().startOf("day"), moment()],
  Yesterday: [
    moment().subtract(1, "days").startOf("day"),
    moment().subtract(1, "days").endOf("day"),
  ],
  "Last 7 Days": [moment().subtract(7, "days"), moment()],
  "This Week": [moment().startOf("week"), moment().endOf("week")],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Last Week": [
    moment().subtract(1, "weeks").startOf("week"),
    moment().subtract(1, "weeks").endOf("week"),
  ],
  "Last Month": [
    moment().subtract(1, "months").startOf("month"),
    moment().subtract(1, "months").endOf("month"),
  ],
  "Last Year": [
    moment().subtract(1, "years").startOf("year"),
    moment().subtract(1, "years").endOf("year"),
  ],
  "Week-To-Date": [moment().startOf("week"), moment()],
  "Month-To-Date": [moment().startOf("month"), moment()],
  "Year-To-Date": [moment().startOf("year"), moment()],
});

export const getDateInteger = (date) => {
  var a = moment(date, "DD-MM-YYYY");
  return new Date(moment(a)).getTime();
};

export const getAppointmentType = (type) => {
  let a = type;
  if (type === "In-Person consultation") {
    a = "In Person";
  } else if (type === "Phone consultation") {
    a = "Phone";
  } else if (type === "Video consultation") {
    a = "Video";
  }
  return a;
};

const EncriptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

// const abc = (ciphertext) => {
//   return ciphertext
//     .toString()
//     .replace("+", "xMl3Jk")
//     .replace("/", "Por21Ld")
//     .replace("=", "Ml32");
// };

// const xyz = (dataString) => {
//   return dataString
//     .toString()
//     .replace("xMl3Jk", "+")
//     .replace("Por21Ld", "/")
//     .replace("Ml32", "=");
// };

export const EncriptString = (string) => {
  if (!string) {
    return;
  }
  let b64 = CryptoJS.AES.encrypt(string, EncriptionKey).toString();
  let e64 = CryptoJS.enc.Base64.parse(b64);
  let hashkey = e64.toString(CryptoJS.enc.Hex);

  return hashkey;
};

export const DecryptString = (string) => {
  if (!string) {
    return;
  }
  let reb64 = CryptoJS.enc.Hex.parse(string);
  let bytes = reb64.toString(CryptoJS.enc.Base64);
  let decrypt = CryptoJS.AES.decrypt(bytes, EncriptionKey);
  let originalText = decrypt.toString(CryptoJS.enc.Utf8);
  return originalText;
};

// const dummySubscription = {
//   trialPeriod: "notStarted",
//   subscriptionPeriod: "notStarted",
//   gracePeriod: "notStarted",
//   subscriptionQuota: {
//     quotaPurchased: 10,
//     quotaUsed: 7,
//     quotaBalance: 3,
//   },
//   trialExpiry: "2022-02-14",
//   subscriptionExpiry: "2022-02-14",
//   graceExpiry: "2022-02-14",
// };
export const updateSubscriptionStatus = (data) => {
  return "Not Activated";
  // return "Trial Activated";
  // return "Activated";
  //  return "Grace Activated";
  // return "Expired";
};

export const getSubscriptionColor = (status) => {
  switch (status) {
    case "Not Activated":
      return "#e79500";

    case "Trial Activated":
      return "#0fa0cf";

    case "Activated":
      return "#28a745";

    case "Grace Activated":
      return "#096dd9";

    case "Expired":
      return "#dc3545";

    default:
      break;
  }
};

export const subscriptionDurations = [
  { value: 90, label: "3 months" },
  { value: 180, label: "6 months" },
  { value: 365, label: "1 year" },
];

export const getDateAfterAddDays = (days) => {
  let createdDate = moment(new Date());
  let addedDate = moment(createdDate).add(days, "d").format("DD MMM YYYY");
  return addedDate;
};

export const getDaysAfterDate = (date) => {
  const DateUtc = moment.utc(date);
  const localDate = moment(DateUtc).local();

  let given = localDate;
  let current = moment().startOf("day");

  let days = moment.duration(current.diff(given)).asDays();
  return Math.ceil(days);
};

export const getPriceAfterDiscountPercentage = (originalPrice, percentage) => {
  return Math.round(originalPrice - (originalPrice * percentage) / 100);
};

export const getDiscountPriceAfterDiscountPercentage = (
  originalPrice,
  percentage
) => {
  return Math.round((originalPrice * percentage) / 100);
};

export const getSubscriptionDaysRemaining = (date) => {
  var eventdate = moment(date, "YYYY-MM-DD");
  var todaysdate = moment();
  return eventdate.diff(todaysdate, "days") + 1;
};

export const getSubscriptionDateFormat = (date) => {
  var eventdate = moment(date, "YYYY-MM-DD");
  return moment(eventdate).format("DD MMM YYYY");
};
export const timesDropDownArray = [
  {
    label: "5 mins",
    value: "5",
  },
  {
    label: "10 mins",
    value: "10",
  },
  {
    label: "15 mins",
    value: "15",
  },
  {
    label: "20 mins",
    value: "20",
  },
  {
    label: "25 mins",
    value: "25",
  },
  {
    label: "30 mins",
    value: "30",
  },
  {
    label: "35 mins",
    value: "35",
  },
  {
    label: "40 mins",
    value: "40",
  },
  {
    label: "45 mins",
    value: "45",
  },
  {
    label: "50 mins",
    value: "50",
  },
  {
    label: "55 mins",
    value: "55",
  },
  {
    label: "60 mins",
    value: "60",
  },
];

export const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const PRACTITIONER_COST_PER_MONTH = 300;
export const CLINIC_TRIAL_DAYS = 30;

export const LAB_PACKAGE_COST_PER_MONTH = 300;
export const LAB_TRIAL_DAYS = 30;
