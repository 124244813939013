import React from "react";
const Dirham = (props) => {
    // return (
    //   <p className={`d-inline ${props.style}`}>&nbsp; &#x62F;&#x002E;&#x0625;  </p>
    // )
    return (
      <p className={`d-inline ${props.style}`}> AED</p>
    )
}
  
export default Dirham

// export const dirham = " د.إ"
export const dirham = " AED"