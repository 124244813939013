import React, { useEffect, useState, useContext } from "react";
import { Popconfirm, Empty, Table, Card } from 'antd';
import { GlobalState } from "context-api";
import { deleteLabLinkedTest, GetLabLinkedTests, updateLabLinkedTest } from "Utils/Services/Lab/QuickBooking";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const LabTestsComponent = () => {
    const { setHeading, Heading, setHeadingCount } = useContext(GlobalState);

    const [labLinkedTests, setLabLinkedTests] = useState([]);
    const [isEditTest, setIsEditTest] = useState(false);
    const [price, setPrice] = useState("");
    const [id, setId] = useState("");

    const handlePrice = (v) => {
        setPrice(v);
    }

    const handleToggleIsEditTest = () => {
        setIsEditTest(prev=>!prev);
    }

    const FetchLinkedTests = async () => {
        const labLinkedTestsResponse = await GetLabLinkedTests();
        if(labLinkedTestsResponse?.status === 200){
         setLabLinkedTests(labLinkedTestsResponse?.data)
        }
    };

    const handleConfirmEditTest = async (row) => {
        handleToggleIsEditTest();
        setId(row.id);
        setPrice(row.price);
    };
    const handleDeleteTest = async (row) => {
        try{
            const deleteResponse = await deleteLabLinkedTest({id: row.id});
            //message.success(deleteResponse.data);
            AntdCustomMessage({
              type: antdMessageTypes.SUCCESS,
              content: deleteResponse.data
            });
            window.location.reload();
        }catch(e){
            //message.error(e.message || e.data || "Something went wrong.")
            AntdCustomMessage({
              type: antdMessageTypes.ERROR,
              content: e.message || e.data || "Something went wrong."
            });
        } 
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (_,row) =>{
                return row.id
            },
        },
        {
            title: 'Test',
            dataIndex: 'test_name',
            key: 'test_name',
            render: (_,row) =>{
                return row.test_name
            },
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (_,row) =>{
                return (
                    <div className="d-flex flex-row align-items-center gap-1">
                        <p>{row.price}</p>
                        <Popconfirm
                            title={`Are you sure you want to edit this Test?`}
                            onConfirm={() => {
                                handleConfirmEditTest(row);
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <EditOutlined style={{ color: 'green', cursor: 'pointer' }} />
                        </Popconfirm>
                    </div>
                )
            },
        },
        {
            title: 'Delete',
            key: 'action',
            render: (_, row) => (
              <Popconfirm
                title={`Are you sure you want to remove this test for you?`}
                onConfirm={() => handleDeleteTest(row)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
              </Popconfirm>
            ),
          }
    ]

    useEffect(()=>{
        FetchLinkedTests();
    },[]);

    useEffect(() => {
        if (setHeading && Heading) {
        setHeading("Lab Tests");
        }
    }, [setHeading, Heading]);

    useEffect(() => {
        setHeadingCount(labLinkedTests.length);
        return () => {
            setHeadingCount(0);
        };
    }, [labLinkedTests]);
    if(labLinkedTests.length === 0){
        return <div className="content d-flex justify-content-center align-items-center">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Lab Tests found"
            />
        </div>
    }
    return (
      <div className="content"> 
      {
        isEditTest ? (
            <Card>
                 <div className="w-full d-flex flex-row align-items-center gap-1 mb-1">
                    <label className="w-25">
                        Test Id: 
                    </label>
                    <input 
                        type="text"
                        value={id}
                        placeholder="Test Id"
                        className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                        disabled={true}
                    />
                </div>
                <div className="w-full d-flex flex-row align-items-center gap-1">
                    <label className="w-25">
                        Test Price: 
                    </label>
                    <input 
                        type="number"
                        value={price}
                        min={0}
                        required= {true}
                        placeholder="Enter Test price"
                        className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                        onChange={(e)=>{handlePrice(e.target.value)}}
                    />
                </div>
                <div className="w-full d-flex flex-row align-items-center gap-1 mt-2">
                <button
                          style={{
                            backgroundColor:price < 0 || !price.length ? "rgba(0,0,0,0.1)":"rgba(3, 111, 211, 1)",
                            color:"white",
                            borderRadius:"5px",
                            border:"none",
                            padding:"5px 1rem",
                            cursor: price < 0 || !price.length ? "not-allowed":"pointer",

                          }}
                          onClick={async () => {
                            try{
                              if(price < 0){
                                AntdCustomMessage({
                                  type: antdMessageTypes.ERROR,
                                  content: "Price should be greater than 0"
                                });
                                return;
                              }
                              const payload = {
                                id,price
                              };
                              const resp = await updateLabLinkedTest(payload);
                              if(resp?.status === 200){
                                AntdCustomMessage({
                                    type: antdMessageTypes.SUCCESS,
                                    content: "Successfully updated price."
                                  });
                                handleToggleIsEditTest();
                                await FetchLinkedTests();
                                setId("");
                                setPrice("");
                              }else{
                                AntdCustomMessage({
                                  type: antdMessageTypes.ERROR,
                                  content: resp?.message || "Something went wrong"
                                })
                              }
                            }catch(e){
                              AntdCustomMessage({
                                type: antdMessageTypes.ERROR,
                                content: "Something went wrong"
                              })
                            }
                          }}
                        >
                          Submit
                        </button>
                </div>
            </Card>
        ):null
      }
        <Card title="List of Lab Tests">
            <Table columns={columns} dataSource={labLinkedTests} />
        </Card>     
      </div>
    );
}

export default LabTestsComponent