import React, { useState } from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { AppointmentStatusFormatter } from "Utils/Constants";
import { Card } from "antd";
import { getRefundData, getRefundIdDetails } from "Utils/Services/Practitioner/Appointments";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import Dirham from "components/CurrencyDirham";


const GeneralTab = ({ data }) => {
  let {
    date = "",
    time = "",
    type = "",
    hospitalName = "",
    bookedOn = "",
    status = "",
    onlinePayment = "",
    offlinePayment = "",
    observation = "",
    appointmentId = "",
    token = "",
    patientRelationName = "",
    relationName = ""
  } = data || {};
  const [refundData, setRefundData] = useState({});
  const handleCloseRefundDetails = ()=>{
    setRefundData({});
  }
  const handleShowRefundDetails = async (id) => {
    setRefundData({});
    const refundIdData = await getRefundIdDetails(id);
    if(refundIdData.status && !refundIdData.data.refundId){
      //message.error("Not raised any refund for this appointment!");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Not raised any refund for this appointment!"
      });
      return;
    }
    const resp = await getRefundData(id);
    setRefundData(resp.data); 
  };

  return (
    <div className="general-info">
      <Row lg="9" md="12" sm="12" className="column-items">
        <Col lg="4" md="4" sm="6" className="item column-item">
          <div className="column-item-inner">
            <h4 className="label-gray" style={{ fontSize: 16 }}>
              Appointment date
            </h4>
            <h4 className="text-black" style={{ fontSize: 16 }}>
              {date}
            </h4>
          </div>
        </Col>
        <Col lg="4" md="4" sm="6" className="item column-item">
          <div className="column-item-inner">
            <h4 className="label-gray" style={{ fontSize: 14 }}>
              Appointment time
            </h4>
            <h4 className="text-black" style={{ fontSize: 14 }}>
              {time}
            </h4>
          </div>
        </Col>
        <Col lg="4" md="4" sm="6" className="item column-item">
          <div className="column-item-inner">
            <h4 className="label-gray" style={{ fontSize: 14 }}>
              Appointment type
            </h4>
            <h4 className="text-black" style={{ fontSize: 14 }}>
              {type}
            </h4>
          </div>
        </Col>
      </Row>
      <Card
        style={{ width: "100%", padding: "10px" }}
        bodyStyle={{ padding: "0px 0px 10px" }}
      >
        <div className="details-wraper">
          <label style={{ fontSize: 14 }}>Clinic name</label>
          <h4 className="text" style={{ fontSize: 14 }}>
            {hospitalName}
          </h4>
        </div>
        <div className="details-wraper">
          <label style={{ fontSize: 14 }}>Booked on</label>
          <h4 className="text" style={{ fontSize: 14 }}>
            {bookedOn }
          </h4>
        </div>
        {
          relationName ? (
            <>
              <div className="details-wraper">
                <label style={{ fontSize: 14 }}>Booked For</label>
                <h4 className="text" style={{ fontSize: 14 }}>
                  {patientRelationName }
                </h4>
              </div>
              <div className="details-wraper">
                <label style={{ fontSize: 14 }}>Relation</label>
                <h4 className="text" style={{ fontSize: 14 }}>
                  {relationName }
                </h4>
              </div>
            </>
          ):null
        }
        <div className="details-wraper">
          <label style={{ fontSize: 14 }}>Appointment status</label>
          <h4 className="text" style={{ fontSize: 14 }}>
            {AppointmentStatusFormatter(status)}
          </h4>
        </div>
        {/* {
          status === "cancelled" &&  (
          <div className="details-wraper" style={{alignItems:"center"}}>
            <label style={{ fontSize: 14 }}>Check Refund Details</label>
            {
              refundData.status ? (
                <Card>
                  <div className="d-flex justify-content-end">
                    <CloseOutlined 
                      style={{marginLeft:"auto",cursor:"pointer"}} 
                      onClick={handleCloseRefundDetails}
                    />
                  </div>
                  <div className="details-wraper">
                    <label style={{ fontSize: 14 }}>Amount :</label>
                    <h4 className="text" style={{ fontSize: 14 }}>
                      {refundData.amount}
                    </h4>
                  </div>
                  <div className="details-wraper">
                    <label style={{ fontSize: 14 }}>Status :</label>
                    <h4 className="text" style={{ fontSize: 14 }}>
                      {refundData.status}
                    </h4>
                  </div>
                </Card>
                ):(
                <Button 
                  type="primary" 
                  onClick={()=>{handleShowRefundDetails(appointmentId)}}
                >
                  Check Refund Status
                </Button>
            )}
          </div>
        )} */}
        <div className="details-wraper">
          <label style={{ fontSize: 14 }}>Online payment</label>
          <h4 className="text" style={{ fontSize: 14 }}>
           {onlinePayment}{onlinePayment ? <Dirham/> : ""}
          </h4>
        </div>
        <div className="details-wraper">
          <label style={{ fontSize: 14 }}>Offline payment</label>
          <h4 className="text" style={{ fontSize: 14 }}>
            {offlinePayment}
          </h4>
        </div>
        <div className="details-wraper">
          <label style={{ fontSize: 14 }}>Additional observation</label>
          <h4 style={{ fontSize: 14 }} className="text">
            {observation}
          </h4>
        </div>
      </Card>
    </div>
  );
};

export default GeneralTab;
