import React, { useEffect, useContext, useState } from "react";
import { GlobalState } from "context-api";
import { 
    Button, 
    Form, 
    Row, Col, 
    Input, 
    Card, Table, 
    message, Popconfirm,
} from 'antd';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { deletePractitionerQA, getPractitionerQuestions, postAddPractitionerQAAnswer, postUpdatePractitionerQAAnswer } from "Utils/Services/Practitioner/QA";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const AskedByPatient = () => {
    const { setHeading, Heading, setHeadingCount } = useContext(GlobalState);
    const [questions, setQuestions] = useState([]);
    const [isAddAnswer, setIsAddAnswer] = useState(true);
    const [isAnswerModalOpen, setIsAnswerModalOpen] = useState(false);

    const [question, setQuestion] = useState({
        question_id: "",
        answer_id: "",
        description: "",
    });

    const handleCloseModal = () => {
        setIsAddAnswer(true);
        setIsAnswerModalOpen(false);
        setQuestion({
            question_id: "",
            answer_id: "",
            description: ""
        });
    }

    const handleConfirmAddAnswer = (row) => {
        setQuestion({
            question_id: row.question_id,
            answer_id: "",
            description: ""
        });
        setIsAddAnswer(true);
        setIsAnswerModalOpen(true);
    }

    const handleConfirmUpdateAnswer = async (row, answerIndex) => {
        setQuestion({
            question_id: row.question_id,
            answer_id: row.answers[answerIndex].answer_id,
            description:row.answers[answerIndex].description
        });
        setIsAddAnswer(false);
        setIsAnswerModalOpen(true);
    }

    const fetchDoctorQuestions = async () => {
        const getQuestions = await getPractitionerQuestions();
        getQuestions && setQuestions(getQuestions);
    }

    const handleAddAnswer = async (values) => {
        try{
            const payload = {
                "question_id":values.question_id,
                "description":values.description,
            };
            const response = await postAddPractitionerQAAnswer(payload);
            if(response && response?.success == true){
                //message.success(response.message);
                AntdCustomMessage({
                    type: antdMessageTypes.SUCCESS,
                    content: response.message
                  });
                handleCloseModal();
                setIsAddAnswer(true);
                setQuestion({
                    question_id: "",
                    answer_id: "",
                    description: ""
                });
                fetchDoctorQuestions();
            }else{
                //message.error(response.message);
                AntdCustomMessage({
                    type: antdMessageTypes.ERROR,
                    content: response.message
                });
            }
        }catch(e){
            //message.error(e.message || "Something went wrong.")
            AntdCustomMessage({
                type: antdMessageTypes.ERROR,
                content: e.message || "Something went wrong."
            });
        }    
    }
    
    const handleUpdateAnswer = async (values) => {
        try{
            const payload = {
                "answer_id": values.answer_id,
                "description":values.description,
            };
            const response = await postUpdatePractitionerQAAnswer(payload);
            if(response && response?.success == true){
                //message.success(response.message);
                AntdCustomMessage({
                    type: antdMessageTypes.SUCCESS,
                    content: response.message
                });
                handleCloseModal();
                setIsAddAnswer(true);
                setQuestion({
                    question_id: "",
                    answer_id: "",
                    description: ""
                });
                fetchDoctorQuestions();
            }else{
                //message.error(response.message);
                AntdCustomMessage({
                    type: antdMessageTypes.ERROR,
                    content: response.message
                });
            }
        }catch(e){
            //message.error(e.message || "Something went wrong.")
            AntdCustomMessage({
                type: antdMessageTypes.ERROR,
                content: e.message || "Something went wrong."
            });
        }    
    }

    const handleDeleteAnswer = async (row,answerIndex) => {
        try{
            const deleteResponse = await deletePractitionerQA({id: row.answers[answerIndex].answer_id});
            //message.success(deleteResponse.data);
            if(deleteResponse){
                AntdCustomMessage({
                    type: antdMessageTypes.SUCCESS,
                    content: deleteResponse.data
                });
                handleCloseModal();
                setIsAddAnswer(true);
                setQuestion({
                    question_id: "",
                    answer_id: "",
                    description: ""
                });
                fetchDoctorQuestions();
            }
        }catch(e){
            //message.error(e?.message || e?.data || "Something went wrong.")
            AntdCustomMessage({
                type: antdMessageTypes.ERROR,
                content: e?.message || e?.data || "Something went wrong."
            });
        }   
    };
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (_,row) =>{
                return row.question_id
            },
        },{
            title: 'Posted',
            dataIdex: 'posted',
            key: 'posted',
            render: (_,row) => {
                return (
                    <div>
                        <p>{row.question_created_on?.split(" ")[0]}</p>
                        <p>{row.author_first_name}{row.author_last_name ? " " + row.author_last_name : ""}</p>
                    </div>
                )
            }
        },{
            title: 'Question',
            dataIdex: 'question',
            key: 'question',
            render: (_, row) => {
                return <p>{row.question_title}</p>
            }
        },{
            title: 'Answer',
            dataIdex: 'answer',
            key: 'answer',
            render: (_, row) => {
                if(row.answers.length === 0 ) {
                    return (
                        <Button 
                            type="primary"
                            onClick={()=>{handleConfirmAddAnswer(row)}}
                            
                        >
                            Answer Now
                        </Button>
                    )
                }
                return(
                    <div>
                       {
                        row.answers.map((each,index)=>{
                            return (
                                <div 
                                    key={index}
                                    className="w-100 d-flex gap-3 align-items-center"
                                >
                                    <p className="mr-auto flex-shrink-1 text-wrap">{each.description}</p>
                                    <div className="ml-5 mr-2">
                                        <Popconfirm
                                            title={`Are you sure you want to delete this Answer?`}
                                            onConfirm={() => {handleDeleteAnswer(row,index)}}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                                        </Popconfirm>
                                    </div>
                                    <div>
                                        <Popconfirm
                                            title={`Are you sure you want to edit this answer?`}
                                            onConfirm={() => handleConfirmUpdateAnswer(row,index)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <EditOutlined style={{ color: 'green', cursor: 'pointer' }} />
                                        </Popconfirm>
                                    </div>
                                </div>
                            )
                        })
                       } 
                    </div>
                )
            }
        },{
            title: 'Likes count\n for\n question',
            dataIdex: 'likes',
            key: 'likes',
            render: (_, row) => {
                return <p>{row.likes_count}</p>
            }
        },{
            title: 'Comments count \n for \n question',
            dataIdex: 'comments',
            key: 'comments',
            render:(_,row) =>{
                return <p>{row.comments.length}</p>
            }
        },{
            title: 'Comments of question',
            dataIdex: 'commentsDescription',
            key: 'commentsDescription',
            render: (_, row) => {
                if(row.comments.length === 0 ) {
                    return (
                        <Card>
                            <p>No comments yet.</p>
                        </Card>
                    )
                }
                return(
                    <div style={{maxHeight:"100px",overflowY:"auto"}}>
                    {
                        row.comments.map((each,index)=>{
                            return (
                                <Card key={"comment"+index}>
                                    <p> {each.comment} </p>
                                </Card>
                            )
                        })
                    } 
                    </div>
                )
        }}
    ]
   
    useEffect(() => {
        if (setHeading && Heading) {
        setHeading("Asked By Patient");
        }
    }, [setHeading, Heading]);

   
    useEffect(()=>{
        fetchDoctorQuestions();
    },[])

    useEffect(()=>{
        setHeadingCount(questions.length);
        return () => {
            setHeadingCount(0);
        };
    },[questions]);

    return (
        <div className="content">
            {
                isAnswerModalOpen ? (
                    <Card title={`${isAddAnswer ? "Add" : "Update"} answer form`}>
                        <Form
                            layout="vertical"
                            name="nest-messages"
                            onFinish={isAddAnswer ? handleAddAnswer : handleUpdateAnswer}
                            initialValues={question}
                        >
                            <Row gutter={{ sm: 8, lg: 24 }}>
                                <Col xs={24} sm={24} lg={12}>
                                    <Form.Item
                                    name={["question_id"]}
                                    label="QuestionID"
                                    rules={[{ required: true }]}
                                    >
                                    <Input size="large" disabled/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {
                                !isAddAnswer ? (
                                    <Row gutter={{ sm: 8, lg: 24 }}>
                                        <Col xs={24} sm={24} lg={12}>
                                            <Form.Item
                                            name={["answer_id"]}
                                            label="AnswerID"
                                            rules={[{ required: true }]}
                                            >
                                            <Input size="large" disabled/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ): null
                            }
                            <Row>
                                <Col xs={24} sm={24} lg={12}>
                                <Form.Item
                                    name={["description"]}
                                    label="write your answer"
                                    rules={[{ required: true }]}
                                    >
                                    <Input size="large" />
                                </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Button type="primary" onClick={()=>{handleCloseModal()}}>Cancel</Button>
                                <Button type="primary" style={{marginLeft:"1rem"}} htmlType="Submit">Submit</Button>
                            </Row>
                        </Form> 
                    </Card> 
                ): null
            }
            <Card title="List of Questions">
                <Table 
                    columns={columns} 
                    dataSource={questions} 
                    bordered
                />
            </Card>    
                 
        </div>
    );
};

export default AskedByPatient;
