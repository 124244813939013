import React, { useState, createContext, useContext } from "react";
import { getAllHospitals, getRequestedHospitals } from "Utils/Services/Practitioner/Hospitals";
import { getMyHospitals } from "Utils/Services/Practitioner/Hospitals";

export const HospitalsContext = createContext();

export const HospitalsContextProvider = (props) => {
  const [SearchKeyword, setSearchKeyword] = useState("");
  const [selectedHospital, setSelectedHospital] = useState("");
  const [showDetailsModal, setshowDetailsModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(12);
  const [data, setData] = useState({
    status: false,
    data: {
      count: 0,
      hospitals: [],
    },
  });

  const [MyPracticesCount, setMyPracticesCount] = useState(0);
  const [AllPracticesCount, setAllPracticesCount] = useState(0);
  const [RequestedPracticesCount, setRequestedPracticesCount] = useState(0);

  const fetchMyHospitalsData = async () => {
    setLoading(true);
    let response = await getMyHospitals({
      start: 0,
      limit: "",
      search: "",
    });
    response && setData(response);
    response && setMyPracticesCount(response?.data?.count);
    setLoading(false);
  };

  const fetchAllHospitalsData = async () => {
    setLoading(true);
    let response = await getAllHospitals({
      start: (pageNo - 1) * limit || 0,
      limit: limit || 12,
      search: SearchKeyword || "",
    });
    response && setData(response);
    response && setAllPracticesCount(response?.data?.count);
    setLoading(false);
  };
  const fetchRequestedHospitalsData = async () => {
    setLoading(true);
    let response = await getRequestedHospitals({
      search: SearchKeyword || "",
    });
    response && setData(response);
    response && setRequestedPracticesCount(response?.data?.count);
    setLoading(false);
  };

  const values = {
    SearchKeyword,
    setSearchKeyword,
    selectedHospital,
    setSelectedHospital,
    showDetailsModal,
    setshowDetailsModal,
    fetchAllHospitalsData,
    loading,
    setLoading,
    pageNo,
    setPageNo,
    limit,
    setLimit,
    data,
    setData,
    fetchMyHospitalsData,
    MyPracticesCount,
    setMyPracticesCount,
    AllPracticesCount,
    setAllPracticesCount,
    fetchRequestedHospitalsData,
    RequestedPracticesCount,
    setAllPracticesCount,
  };

  return <HospitalsContext.Provider value={values} {...props} />;
};

export const useHospitals = () => {
  const context = useContext(HospitalsContext);
  if (context === undefined)
    throw new Error(`must be used within a UserContextProvider.`);
  return context;
};
