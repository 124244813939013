import React, { useState, useEffect } from "react";
import { Divider, Row, Col, Form, Button, message, TimePicker } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useSchedule } from "./useSchedule";
import { modelUpdateScheduleTimingPayload } from "models/practitioner/schedule-timings.api.models";
import { postDaySlots } from "Utils/Services/Practitioner/ScheduleTimings";
import { nanoid } from "nanoid";
import moment from "moment";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const ManageSlotsCard = ({ scheduleData, selectedHospital, fetchData }) => {
  const { selectedDay, setIsModalOpen } = useSchedule();
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [timesData, setTimesData] = useState(() => {
    //create a unique id for each data
    return scheduleData.map((item) => ({ ...item, id: nanoid() }));
  });
  // eslint-disable-next-line
  const [ExistingData, setExistingData] = useState(() => {
    //create a unique id for each data
    return scheduleData.map((item) => ({ ...item, id: nanoid() }));
  });

  useEffect(() => {}, [selectedDay]);

  const addSchedule = () => {
    setTimesData([
      ...timesData,
      { start: "00:00", end: "24:00", id: nanoid() },
    ]);
  };

  const [AddedTimings, setAddedTimings] = useState([]);
  const [RemovedTimings, setRemovedTimings] = useState([]);
  const [DuplicateTimings, setDuplicateTimings] = useState(false);

  //new data added cant be in the range of the previous data
  const checkRange = (start, end, index) => {
    const newData = [...timesData];
    const prevData = newData.filter((item, i) => i < index);
    const isInRange = prevData.some((item) => {
      const startTime = moment(item.start, "HH:mm");
      const endTime = moment(item.end, "HH:mm");
      const newStartTime = moment(start, "HH:mm");
      const newEndTime = moment(end, "HH:mm");
      return (
        (newStartTime.isBetween(startTime, endTime) ||
          newEndTime.isBetween(startTime, endTime)) &&
        item.id !== newData[index].id
      );
    });
    return isInRange;
  };

  const removeSchedule = (id) => {
    let data = timesData.filter((item) => item.id !== id);
    setTimesData(data);
  };

  const onSubmit = async () => {
    setIsBtnLoading(true);
    //check if the data is valid
    let isValid = true;
    timesData.forEach((item, index) => {
      if (
        !item.start ||
        !item.end ||
        item.start === "" ||
        item.end === "" ||
        item.start === item.end ||
        checkRange(item.start, item.end, index)
      ) {
        isValid = false;
      }
    });
    if (!isValid) {
      //message.error("Please enter valid time range");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Please enter valid time range"
      });
      setIsBtnLoading(false);
      return;
    }
    //leave can't be added in t
    let res = await postDaySlots(
      modelUpdateScheduleTimingPayload(
        timesData,
        {},
        selectedHospital,
        selectedDay,
        AddedTimings,
        RemovedTimings
      )
    );
    if (res?.status) {
      //message.success("Schedule updated successfully");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Schedule updated successfully"
      });
      setIsModalOpen(false);
      setIsModalOpen(false);
      fetchData(selectedHospital);
    } else {
      //message.error(res.message);
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: res.message
      });
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (timesData) {
      let addedTimings = timesData.filter(
        // eslint-disable-next-line
        ({ start: start, end: end }) =>
          !ExistingData.some(
            ({ start: start1, end: end1 }) => start === start1 && end === end1
          ) &&
          start !== "" &&
          end !== ""
      );

      let removedTimings = ExistingData.filter(
        // eslint-disable-next-line
        ({ start: start, end: end }) =>
          !timesData.some(
            ({ start: start1, end: end1 }) => start === start1 && end === end1
          )
      );

      if (
        timesData?.length > ExistingData?.length &&
        addedTimings.length === 0
      ) {
        //message.error("Duplicate timings are not allowed");
        AntdCustomMessage({
          type: antdMessageTypes.ERROR,
          content: "Duplicate timings are not allowed"
        });
        setDuplicateTimings(true);
      } else {
        setDuplicateTimings(false);
      }

      setAddedTimings(
        addedTimings.map((item) => {
          return { start: item.start, end: item.end };
        })
      );
      setRemovedTimings(
        removedTimings.map((item) => {
          return { start: item.start, end: item.end };
        })
      );
    }
    // eslint-disable-next-line
  }, [timesData]);

  return (
    <div className="manage-slots-card">
      <div className="heading-container">
        <h2 className="manage-slot-heading">Add Time Slots</h2>
      </div>
      <Divider />
      <div className="manage-container">
        <div style={{ textAlign: timesData?.length ? "right" : "center" }}>
          <Button type="secondary" onClick={() => addSchedule()}>
            {timesData?.length ? "Add More" : "Add Slots"}
          </Button>
        </div>
        <Form layout="vertical" name="nest-messages">
          {timesData.length
            ? timesData.map((item) => (
                <Row gutter={{ sm: 8, lg: 24 }} key={item.id}>
                  <Col xs={24} sm={24} lg={10}>
                    <Form.Item label="Start Time">
                      <TimePicker
                        size="large"
                        use12Hours
                        format="h:mm a"
                        className="w-full"
                        hourStep={1}
                        minuteStep={60}
                        allowClear={false}
                        showNow={false}
                        defaultValue={
                          item.start
                            ? moment(item.start, "HH:mm")
                            : moment("00:00", "HH:mm")
                        }
                        onChange={(value) => {
                          //update start time with id
                          setTimesData((prevState) => {
                            const newData = [...prevState];
                            newData.map((time) => {
                              if (item.id === time.id) {
                                item.start = value.format("HH:mm");
                              }
                              return item;
                            });
                            return newData;
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={10}>
                    <Form.Item label="End Time">
                      <TimePicker
                        size="large"
                        use12Hours
                        format="h:mm a"
                        className="w-full"
                        hourStep={1}
                        minuteStep={60}
                        allowClear={false}
                        showNow={false}
                        defaultValue={
                          item.end
                            ? moment(item.end, "HH:mm")
                            : moment("24:00", "HH:mm")
                        }
                        onChange={(value) => {
                          //update end time with id
                          setTimesData((prevState) => {
                            const newData = [...prevState];
                            newData.map((time) => {
                              if (item.id === time.id) {
                                item.end = value.format("HH:mm");
                              }
                              return item;
                            });
                            return newData;
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    lg={4}
                    style={{ display: "flex", alignItems: "center" }}
                    className="slot-remove-btn"
                  >
                    <div
                      className="btn-delete"
                      onClick={() => removeSchedule(item.id)}
                    >
                      <DeleteOutlined className="icon" />
                    </div>
                  </Col>
                </Row>
              ))
            : null}
        </Form>

        {ExistingData?.length || timesData?.length ? (
          <div className="flex justify-center" style={{ marginTop: 10 }}>
            <Button
              disabled={DuplicateTimings}
              size="large"
              type="primary"
              loading={isBtnLoading}
              style={{ width: "100%" }}
              onClick={() => onSubmit()}
            >
              {DuplicateTimings ? "Remove Duplicate Timings" : "Save Changes"}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ManageSlotsCard;
