import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
} from "antd";
import { GlobalState } from "context-api";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { getLabPackagesByLabId } from "Utils/Services/Lab";

import {
  AddPatientByInstantBooking,
  CheckPatientExist,
  saveQuickBookingPaymentDetails,
  saveQuickBookingPackageBooking,
  postSaveLabTest,
  GetLabLinkedTests,
  postSaveLinkedTest,
  postSaveLabTestBooking,
} from "Utils/Services/Lab/QuickBooking";
import PhoneInputComponent from "../../../../components/PhoneInput";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import { GetLabTests } from "Utils/Services/Lab/LabPackages";

const QuickBooking = () => {
  const { setHeading, Heading, UserData } = useContext(GlobalState);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isPatientExist, setIsPatientExist] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [error, setError] = useState({
    isError: false,
    message: [],
  });
  const [MyPackages, setMyPackages] = useState(null);
  const [SelectedPackage, setSelectedPackage] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);
  const [availableTests, setAvailableTests] = useState(null);
  const [labLinkedTests, setLabLinkedtests] = useState([]);
  const [filteredAvailableTests, setFilteredAvailableTests] = useState([]);
  const [typedText, setTypedText] = useState('');
  const [testDetails, setTestDetails] = useState({
    testName : "",
    tags:"",
    units:"",
    description:"",
    normalValueRange:"",
    price:""
  });
  const [showAddCommontest, setShowAddCommonTest] = useState(false);
  const [isNotLinkedtest, setIsNotLinkedTest] = useState("");
  const [linkedtestPrice, setLinkedTestPrice] = useState("");

  const [mobileNumber, setMobileNumber] = useState({
    mobile: "",
    country: "ae",
  });

  const handleLinkedTestPrice = (value) => {
    setLinkedTestPrice(value);
    setTestDetails({
      testName : "",
      tags:"",
      units:"",
      description:"",
      normalValueRange:"",
      price:""
    })
  };

  const handleTypedText = (value) => {
    setTypedText(value);
    setTestDetails(prev=>({
      ...prev,
      testName: value
    }));
    setSelectedTest("");
  };

  const handleTest = (event) => {
    event.persist();
    setTestDetails(prev=>({
        ...prev,
        [event.target.name]: event.target.value
    }));
  }

  const handleChangeSelectTest = (value) => {
    handleTypedText(value);
    const filtered = availableTests
      ?.filter(each=>each.test_name.toLowerCase().includes(value.toLowerCase()));
    if(filtered.length > 0){
      setFilteredAvailableTests([...filtered]);
      setShowAddCommonTest(false);
    }else{
      setFilteredAvailableTests([]);
      setShowAddCommonTest(true);
    }
  };
  
  const handleOptionClick = (option) => {
    setTypedText(option.test_name);
    setFilteredAvailableTests([]);
    const checkLinkedtests = labLinkedTests?.find(each=>each.test_name === option.test_name);
    
    if(checkLinkedtests){
      setSelectedTest(checkLinkedtests.id);
      setLinkedTestPrice(checkLinkedtests.price);
      setIsNotLinkedTest("false");
    }else{
      setIsNotLinkedTest("true");
    }
  };

  const handleLabLinkedTest = (values) => {
    setLabLinkedtests(values);
  }

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Instant Booking");
    }
  }, [setHeading, Heading]);

  const FetchLinkedTests = async () => {
    const labLinkedTestsResponse = await GetLabLinkedTests();
    if(labLinkedTestsResponse?.status === 200){
     setLabLinkedtests(labLinkedTestsResponse?.data)
    }
  };
  const FetchLabTests = async () => {
    const labTestsResponse = await GetLabTests();
    if(labTestsResponse?.status === 200){
      setAvailableTests(labTestsResponse?.data?.labtests);
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      const myPackagesResponse = await getLabPackagesByLabId(UserData.userid);
      if(myPackagesResponse){
        const FilteredPackages = myPackagesResponse.filter(
          (item) => item.active_flag === 1
        );
        setMyPackages(FilteredPackages);
      }
    };
    fetchData();
    FetchLabTests();
    FetchLinkedTests();
  }, []);


  const checkPatientExists = async (value) => {
    if (value.dialercode === "91" && value.mobile.length !== 10) {
      //message.error("Enter valid mobile number");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Enter valid mobile number"
      });
      return;
    }
    if (value.dialercode === "971" && value.mobile.length !== 9) {
      //message.error("Enter valid mobile number");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Enter valid mobile number"
      });
      return;
    }

    setIsBtnLoading(true);
    setSelectedPackage(null);
    setIsPatientExist(null);
    setPatientData(null);
    setError({
      isError: false,
      message: [],
    });

    const { status, patientExist, data } = await CheckPatientExist(value);
    if (status && patientExist) {
      setIsPatientExist(true);
      setPatientData(data);
    } else if (status && !patientExist) {
      setIsPatientExist(false);
      setPatientData(null);
    } else {
      setError({
        isError: true,
        message: data,
      });
    }
    setIsBtnLoading(false);
  };
  const addBookingWithNewPatient = async (values) => {
    let { first_name, last_name, dob, gender } = values;

    const addPatientResponse = await AddPatientByInstantBooking({
      first_name,
      last_name,
      dob: moment(dob).format("YYYY-MM-DD"),
      gender,
      dialercode: mobileNumber.country,
      mobile: mobileNumber.mobile.substring(mobileNumber.country.length),
    });
    if (addPatientResponse && addPatientResponse?.status === 200) {
      SelectedPackage && await addBooking(addPatientResponse?.data?.patient_id, SelectedPackage);
      selectedTest && await addTestBooking(addPatientResponse?.data?.patient_id, selectedTest);
    }
  };

  const addBooking = async (patient_id, package_id) => {
    let payload = {
      patient_id: "SDP" + patient_id,
      package_id: package_id,
    };

    const response = await saveQuickBookingPaymentDetails(payload);

    if (response?.order_id) {
      let paylaod2 = {
        order_id: response.order_id,
      };
      const response2 = await saveQuickBookingPackageBooking(paylaod2);
      if (response2?.status === "lab_appointment_success") {
        //message.success("Instant booking for lab appointment done successfully");
        AntdCustomMessage({
          type: antdMessageTypes.SUCCESS,
          content: "Instant booking for lab appointment done successfully"
        });
      }

      setPatientData(null);
      setSelectedPackage(null);
      setIsPatientExist(null);
      setSelectedTest("");
      setTypedText("");
      setLinkedTestPrice("");
      setIsNotLinkedTest("");
    }
    setLinkedTestPrice("");
  };

  const addLinkLabTest = async (testId, testDetails) => {
    const linkTestPayload = {
      test_id: testId,
      price: testDetails?.price,
    }
    const linkTestResp = await postSaveLinkedTest(linkTestPayload);
    
    if(linkTestResp?.status===200){
      const labLinkedTestsResp = await GetLabLinkedTests();
      labLinkedTestsResp && handleLabLinkedTest(labLinkedTestsResp?.data)
      const linkedTestId = labLinkedTestsResp?.data.find(each=>each.test_name === typedText);
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Lab Test linked. Proceed for quick booking."
      });
      linkedTestId && setSelectedTest(linkedTestId.id);
      setShowAddCommonTest(false);
      setIsNotLinkedTest("");
    }else{
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: linkTestResp.message || "Something went wrong",
      })
    }
  }
  const addTestBooking = async (patient_id, test_id) => {
    let payload = {
      patient_id: "SDP" + patient_id,
      test_id: test_id,
    };

    const response = await postSaveLabTestBooking(payload);
    if (response?.data === "Lab test appointment booked successfully.") {
      //message.success("Instant booking for lab appointment done successfully");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Instant booking for lab appointment done successfully"
      });
    }

    setPatientData(null);
    setSelectedPackage(null);
    setIsPatientExist(null);
    setSelectedTest("");
    setTypedText("");
    setLinkedTestPrice("");
    setIsNotLinkedTest("");
  };

  return (
    <div className="content">
      <div className="quickbooking-container">
        <Row gutter={{ sm: 8, lg: 24 }} style={{ marginTop: "30px" }}>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item>
              <PhoneInputComponent
                label="Mobile Number"
                idFor="mobile"
                placeholder="Enter mobile Number"
                value={mobileNumber.mobile}
                country="ae"
                type="lab"
                error={error.isError}
                errorMessage={error.message}
                onChange={(value, country) => {
                  setMobileNumber({
                    mobile: value,
                    country: country.dialCode,
                  });
                }}
              />
            </Form.Item>
            <Button
              style={{ marginTop: "-10px", marginBottom: "20px", height: 40 }}
              type="primary"
              onClick={() =>{
                mobileNumber.mobile.length === 0 && message.error('Please enter mobileNumber!');
                mobileNumber.mobile.length > 1 &&
                checkPatientExists({
                  dialercode: mobileNumber.country,
                  mobile: mobileNumber.mobile.substring(
                    mobileNumber.country.length
                  ),
                })
              }
              }
              loading={isBtnLoading}
            >
              Check Patient
            </Button>
          </Col>
        </Row>
        {isPatientExist !== null ? (
          isPatientExist ? (
            <Form layout="vertical">
              <Alert
                message="Patient Already exists"
                type="success"
                closable
                style={{ marginBottom: "1rem", width: "40%" }}
              />
              <Row gutter={{ sm: 8, lg: 24 }}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item label="Name">
                    <Input
                      disabled
                      size="large"
                      type="text"
                      readOnly
                      value={patientData?.patient}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item label="Date of Birth">
                    <Input
                      disabled
                      size="large"
                      type="text"
                      readOnly
                      value={
                        patientData?.dob &&
                        moment(
                          new Date(
                            moment(moment(patientData?.dob, "YYYY-MM-DD"))
                          )
                        ).format("DD MMM YYYY")
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ sm: 8, lg: 24 }} style={{ marginTop: "10px" }}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["package_id"]}
                    label="Lab Package"
                    // rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select lab package"
                      optionFilterProp="children"
                      size="large"
                      onChange={(val) => {
                        setSelectedPackage(val)
                      }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    >
                      {[{package_id:"",package_name:"none"},...MyPackages]?.map((item) => (
                        <Select.Option key={item.package_id}>
                          {item.package_name}
                        </Select.Option>
                      ))}
                    </Select>
                    
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ sm: 8, lg: 24 }} style={{ marginTop: "10px" }}>
              <Col xs={24} sm={24} lg={12}>

              <Form.Item
                  name={["lab_test"]}
                  label="Lab Test Name"
                >
                 { typedText && filteredAvailableTests.length ? <div 
                      style={{
                        width:"100%",
                        maxHeight: "200px",
                        padding:"10px",
                        overflow: "scroll",
                        scrollbarWidth: 0,
                        background: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        boxShadow: '0 2px 4px rgba(0,0,0,.1)',
                      }}>
                      {filteredAvailableTests.map((test) => (
                        <p key={test.test_id} onClick={(e)=>{handleOptionClick(test)}}>{test.test_name}</p>
                      ))}
                  </div> : null}
                  <input
                      type="text"
                      className="d-block" 
                      placeholder="Type here"
                      value={typedText}
                      onChange={(e)=>{handleChangeSelectTest(e.target.value)}}
                      style={{
                        width: '200px',
                        padding: '5px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        boxShadow: 'inset 0 1px 2px rgba(0,0,0,.1)',
                      }}
                    />
                  {
                    showAddCommontest ? (
                     <div className="w-full d-flex flex-column align-center mt-2 gap-1">
                      <div className="w-full d-flex flex-row align-items-center gap-1">
                        <label className="w-25">
                          Test Tag: 
                        </label>
                        <input 
                          name="tags"
                          type="text"
                          placeholder="Add Test Tag"
                          className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                          onChange={(e)=>{handleTest(e)}}
                        />
                      </div>
                      <div className="w-full d-flex flex-row align-items-center gap-1">
                        <label className="w-25">
                        Test Unit:
                        </label>
                        <input 
                          name="units"
                          type="text"
                          placeholder="Add Test Units"
                          className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                          onChange={(e)=>{handleTest(e)}}
                        />
                      </div>
                      <div className="w-full d-flex flex-row align-items-center gap-1">
                        <label className="w-25">
                        Test Description:
                        </label>
                        <input 
                          name="description"
                          type="text"
                          placeholder="Add Test description"
                          className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                          onChange={(e)=>{handleTest(e)}}
                        />
                      </div>
                      <div className="w-full d-flex flex-row align-items-center gap-1">
                        <label className="w-25">
                        Test Normal Value Range:
                        </label>
                        <input 
                          name="normalValueRange"
                          type="text"
                          placeholder="Enter Normal Value Range"
                          className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                          onChange={(e)=>{handleTest(e)}}
                        />
                      </div>
        
                      <div className="w-full d-flex flex-row align-items-center gap-1">
                        <label className="w-25">
                        Price:
                        </label>
                        <input 
                          name="price"
                          type="number" 
                          required
                          placeholder="Enter price for test" 
                          className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                          onChange={(e)=>{handleTest(e)}}
                        />
                      </div>
                      <div>
                        <button
                          style={{
                            backgroundColor:"transparent",
                            borderRadius:"5px",
                            border:"1px solid black",
                            padding:"5px 1rem"

                          }}
                          onClick={async () => {
                            try{
                              if(!testDetails?.price){
                                AntdCustomMessage({
                                  type: antdMessageTypes.ERROR,
                                  content: "Add price to add test"
                                });
                                return;
                              }
                              const payload = {
                                test_name : typedText,
                                tags: testDetails?.tags ||"",
                                units: testDetails?.units ||"",
                                description: testDetails?.description ||"",
                                normal_value_range: testDetails?.normalValueRange ||""
                              };
                              const resp = await postSaveLabTest(payload);
                              if(resp?.status === 200){
                                await FetchLabTests();
                                const testId = resp.data.insertId || "";
                                if(testId){
                                  await addLinkLabTest(testId,testDetails);
                                }
                              }else{
                                AntdCustomMessage({
                                  type: antdMessageTypes.ERROR,
                                  content: resp?.message || "Something went wrong"
                                })
                              }
                            }catch(e){
                              AntdCustomMessage({
                                type: antdMessageTypes.ERROR,
                                content: "Something went wrong"
                              })
                            }
                          }}
                        >
                          Add Test
                        </button>
                      </div>
                     </div>
                    ):null
                  }
                  {
                    ( isNotLinkedtest === "true" && !showAddCommontest && !SelectedPackage)?
                      <div className="border shadow mt-3">
                       <div className="w-full d-flex flex-row align-items-center gap-1 p-3">
                          <label className="w-25">
                            Price:
                          </label>
                          <input 
                            type="number" 
                            placeholder="Enter price for test" 
                            className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                            onChange={(e)=>{handleLinkedTestPrice(e.target.value)}}
                          />
                        </div>
                        <div className="p-3">
                          <button
                            style={{
                              backgroundColor:"transparent",
                              borderRadius:"5px",
                              border:"1px solid black",
                              padding:"5px 1rem"
                            }}
                            onClick={async ()=>{
                              if(!linkedtestPrice){
                                AntdCustomMessage({
                                  type: antdMessageTypes.ERROR,
                                  content: "Add price to link test."
                                });
                                return;
                              }
                              const testId = availableTests.find(each=>each.test_name===typedText);
                              testId && await addLinkLabTest(testId.test_id,{price:linkedtestPrice});
                            }}
                          >
                            Add Price
                          </button>
                        </div>
                     </div>
                    :(isNotLinkedtest === "false" && !showAddCommontest && !SelectedPackage) ?
                     <div className="w-full d-flex flex-row align-items-center gap-1 mt-3">
                        <label className="w-25">
                          Test Price:
                        </label>
                        <input 
                          type="number" 
                          placeholder="Enter price for test" 
                          className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                          value={linkedtestPrice}
                          disabled={true}
                        />
                      </div>
                    :null
                  }
                </Form.Item>
              </Col>
              </Row>
              <Row>
               Select either package or lab test.
              </Row>
              <Row style={{ justifyContent: "flex-start" }}>
                <Button
                  style={{ height: 40 }}
                  type="primary"
                  disabled={!patientData || (!SelectedPackage && !selectedTest) || (SelectedPackage && selectedTest)}
                  onClick={async () =>{
                    SelectedPackage && await addBooking(patientData?.id, SelectedPackage);
                    selectedTest && await addTestBooking(patientData?.id, selectedTest);
                  }}
                >
                  Book Appointment
                </Button>
              </Row>
            </Form>
          ) : (
            <Form layout="vertical" onFinish={addBookingWithNewPatient}>
              <Alert
                message="Patient not registered"
                description="Please fill the following fields to register user and book appointment."
                type="warning"
                style={{ marginBottom: "1rem" }}
                closable
              />
              <Row gutter={{ sm: 8, lg: 24 }}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["first_name"]}
                    label="First Name"
                    rules={[{ required: true }]}
                  >
                    <Input size="large" type="text" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["last_name"]}
                    label="Last Name"
                    rules={[{ required: true }]}
                  >
                    <Input size="large" type="text" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ sm: 8, lg: 24 }}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["gender"]}
                    label="Gender"
                    rules={[{ required: true }]}
                  >
                    <Select
                      optionFilterProp="children"
                      size="large"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    >
                      <Select.Option value="male">Male</Select.Option>
                      <Select.Option value="female">Female</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["dob"]}
                    label="Date of Birth"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      size="large"
                      format={"DD MMM YYYY"}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ sm: 8, lg: 24 }} style={{ marginTop: "10px" }}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["package_id"]}
                    label="Lab Package"
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select lab package"
                      optionFilterProp="children"
                      size="large"
                      onChange={(val) => setSelectedPackage(val)}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    >
                      {[{package_id:"",package_name:"none"},...MyPackages]?.map((item) => (
                        <Select.Option key={item.package_id}>
                          {item.package_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ sm: 8, lg: 24 }} style={{ marginTop: "10px" }}>
              <Col xs={24} sm={24} lg={12}>

              <Form.Item
                  name={["lab_test"]}
                  label="Lab Test Name"
                  // rules={[{ required: true }]}
                >
                 { typedText && filteredAvailableTests.length ? <div 
                      style={{
                        width:"100%",
                        maxHeight: "200px",
                        padding:"10px",
                        overflow: "scroll",
                        scrollbarWidth: 0,
                        background: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        boxShadow: '0 2px 4px rgba(0,0,0,.1)',
                      }}>
                      {filteredAvailableTests.map((test) => (
                        <p key={test.test_id} onClick={(e)=>{handleOptionClick(test)}}>{test.test_name}</p>
                      ))}
                  </div> : null}
                  <input
                      type="text"
                      className="d-block" 
                      placeholder="Type here"
                      value={typedText}
                      onChange={(e)=>{handleChangeSelectTest(e.target.value)}}
                      style={{
                        width: '200px',
                        padding: '5px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        boxShadow: 'inset 0 1px 2px rgba(0,0,0,.1)',
                      }}
                    />
                  {
                    showAddCommontest ? (
                     <div className="w-full d-flex flex-column align-center mt-2 gap-1">
                      <div className="w-full d-flex flex-row align-items-center gap-1">
                        <label className="w-25">
                          Test Tag: 
                        </label>
                        <input 
                          name="tags"
                          type="text"
                          placeholder="Add Test Tag"
                          className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                          onChange={(e)=>{handleTest(e)}}
                        />
                      </div>
                      <div className="w-full d-flex flex-row align-items-center gap-1">
                        <label className="w-25">
                        Test Unit:
                        </label>
                        <input 
                          name="units"
                          type="text"
                          placeholder="Add Test Units"
                          className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                          onChange={(e)=>{handleTest(e)}}
                        />
                      </div>
                      <div className="w-full d-flex flex-row align-items-center gap-1">
                        <label className="w-25">
                        Test Description:
                        </label>
                        <input 
                          name="description"
                          type="text"
                          placeholder="Add Test description"
                          className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                          onChange={(e)=>{handleTest(e)}}
                        />
                      </div>
                      <div className="w-full d-flex flex-row align-items-center gap-1">
                        <label className="w-25">
                        Test Normal Value Range:
                        </label>
                        <input 
                          name="normalValueRange"
                          type="text"
                          placeholder="Enter Normal Value Range"
                          className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                          onChange={(e)=>{handleTest(e)}}
                        />
                      </div>
        
                      <div className="w-full d-flex flex-row align-items-center gap-1">
                        <label className="w-25">
                        Price:
                        </label>
                        <input 
                          name="price"
                          type="number" 
                          required
                          placeholder="Enter price for test" 
                          className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                          onChange={(e)=>{handleTest(e)}}
                        />
                      </div>
                      <div>
                        <button
                          style={{
                            backgroundColor:"transparent",
                            borderRadius:"5px",
                            border:"1px solid black",
                            padding:"5px 1rem"

                          }}
                          onClick={async () => {
                            try{
                              if(!testDetails?.price){
                                AntdCustomMessage({
                                  type: antdMessageTypes.ERROR,
                                  content: "Add price to add test"
                                });
                                return;
                              }
                              const payload = {
                                test_name : typedText,
                                tags: testDetails?.tags ||"",
                                units: testDetails?.units ||"",
                                description: testDetails?.description ||"",
                                normal_value_range: testDetails?.normalValueRange ||""
                              };
                              const resp = await postSaveLabTest(payload);
                              if(resp?.status === 200){
                                await FetchLabTests();
                                const testId = resp.data.insertId || "";
                                if(testId){
                                  await addLinkLabTest(testId,testDetails);
                                }
                              }else{
                                AntdCustomMessage({
                                  type: antdMessageTypes.ERROR,
                                  content: resp?.message || "Something went wrong"
                                })
                              }
                            }catch(e){
                              AntdCustomMessage({
                                type: antdMessageTypes.ERROR,
                                content: "Something went wrong"
                              })
                            }
                          }}
                        >
                          Add Test
                        </button>
                      </div>
                     </div>
                    ):null
                  }
                  {
                    ( isNotLinkedtest === "true" && !showAddCommontest && !SelectedPackage)?
                      <div className="border shadow mt-3">
                       <div className="w-full d-flex flex-row align-items-center gap-1 p-3">
                          <label className="w-25">
                            Price:
                          </label>
                          <input 
                            type="number" 
                            placeholder="Enter price for test" 
                            className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                            onChange={(e)=>{handleLinkedTestPrice(e.target.value)}}
                          />
                        </div>
                        <div className="p-3">
                          <button
                            style={{
                              backgroundColor:"transparent",
                              borderRadius:"5px",
                              border:"1px solid black",
                              padding:"5px 1rem"
                            }}
                            onClick={async ()=>{
                              if(!linkedtestPrice){
                                AntdCustomMessage({
                                  type: antdMessageTypes.ERROR,
                                  content: "Add price to link test."
                                });
                                return;
                              }
                              const testId = availableTests.find(each=>each.test_name===typedText);
                              testId && await addLinkLabTest(testId.test_id,{price:linkedtestPrice});
                            }}
                          >
                            Add Price
                          </button>
                        </div>
                     </div>
                    :(isNotLinkedtest === "false" && !showAddCommontest && !SelectedPackage) ?
                     <div className="w-full d-flex flex-row align-items-center gap-1 mt-3">
                        <label className="w-25">
                          Test Price:
                        </label>
                        <input 
                          type="number" 
                          placeholder="Enter price for test" 
                          className="rounded flex-grow-1 bg-light p-2 border-top-0 border-right-0 border-left-0 border-bottom"
                          value={linkedtestPrice}
                          disabled={true}
                        />
                      </div>
                    :null
                  }
                </Form.Item>
              </Col>
              </Row>
              <Row>
               Select either package or lab test.
              </Row>
              <Row style={{ justifyContent: "flex-start" }}>
                <Button
                  style={{ height: 40 }}
                  type="primary"
                  htmlType="submit"
                  disabled={(!SelectedPackage && !selectedTest) || (SelectedPackage && selectedTest)}
                >
                  Register and Book Appointment
                </Button>
              </Row>
            </Form>
          )
        ) : null}
      </div>
    </div>
  );
};
export default QuickBooking;
