import { Button, message, Popconfirm } from "antd";
import React, { useState } from "react";
// import { changeAppointmentStatus as changeLabAppointmentStatus } from "../../../../Utils/Services";
import { changeAppointmentStatus } from "Utils/Services/Practitioner/Appointments";
import { useAppointments } from "./useAppointments";
import { useHistory } from "react-router-dom";
import { EncriptString } from "Utils/Constants";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const STATUS = {
  PENDING: "pending",
  APPROVE: "approved",
  CANCEL: "cancelled",
  RESCHEDULE: "rescheduled",
  CHECKIN: "check-in",
  COMPLETED: "completed",
};

export const SetPractitionerAppointmentButtons = (
  row,
  today = false,
  FetchData = () => {},
  patientId = ""
) => {
  const history = useHistory();
  const {
    setSelectedAppointmentId,
    setSelectedHospitalId,
    setShowRescheduleModal,
  } = useAppointments();

  const [isBtnLoading, setIsBtnLoading] = useState({ status: false, id: null });

  //Actions handler for buttons
  const set_data = (needed_status, id) => {
    var data = {
      appointment_id: id,
      appointment_status: needed_status,
    };
    setIsBtnLoading({ status: true, id: needed_status });
    //redirecting to video/audio call
    changeAppointmentStatus(data).then((response) => {
      if (response?.status && response?.isRedirect) {
        window.open(response.redirectUrl);
        FetchData(null, null, patientId);
        return;
      }
      if (response.status) {
        // message.success(
        //   `Appointment ${
        //     needed_status === STATUS.CHECKIN ? "checked in" : needed_status
        //   } successfully`
        // );
        AntdCustomMessage({
          type: antdMessageTypes.SUCCESS,
          content: `Appointment ${
            needed_status === STATUS.CHECKIN ? "checked in" :
             (needed_status === STATUS.APPROVE ? "confirmed" : needed_status)
          } successfully`
        });
        FetchData(null, null);
      } else {
        //message.warning("Something went wrong");
        AntdCustomMessage({
          type: antdMessageTypes.WARNING,
          content: "Something went wrong"
        });
      }
      setIsBtnLoading(true);
    });
  };

  //Buttons UI for each status starts here
  const VideoAndAudioButtons = ({ row, today }) => {
    return (
      <>
        {row.appointmentType === "Video consultation" && today && (
          <Button
            className="videoconference"
            onClick={() => set_data("videoconference", row.appointmentId)}
          >
            Start Video Call
          </Button>
        )}
        {row.appointmentType === "Phone consultation" && today && (
          <Button
            className="videoconference"
            onClick={() => set_data("videoconference", row.appointmentId)}
          >
            Start Phone Call
          </Button>
        )}
      </>
    );
  };

  const RescheduleButton = ({ row }) => {
    return (
      <Popconfirm
        placement="topRight"
        title="Are you sure you want to reschedule?"
        onConfirm={() => {
          setSelectedHospitalId(row.clinicId);
          setSelectedAppointmentId(row.appointmentId);
          setShowRescheduleModal(true);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button
          className="reschedule"
          loading={isBtnLoading.status && isBtnLoading.id === STATUS.RESCHEDULE}
          disabled={isBtnLoading.status}
        >
          Reschedule
        </Button>
      </Popconfirm>
    );
  };

  const CheckInButton = ({ row }) => {
    return (
      <>
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to checkin?"
          onConfirm={() => {
            set_data(STATUS.CHECKIN, row.appointmentId);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            className="checkin"
            loading={isBtnLoading.status && isBtnLoading.id === STATUS.CHECKIN}
            disabled={isBtnLoading.status}
          >
            Check In
          </Button>
        </Popconfirm>
      </>
    );
  };

  const CheckoutButton = ({ row }) => {
    return (
      <>
        <Button
          onClick={() => {
            history.push(
              "/practitioner/reports/" +
                EncriptString(row.appointmentId.toString()) +
                "/" +
                EncriptString(row.status)
            );
          }}
        >
          Reports and Prescription
        </Button>
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to checkout?"
          onConfirm={() => {
            set_data(STATUS.COMPLETED, row.appointmentId);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            className="checkin"
            loading={
              isBtnLoading.status && isBtnLoading.id === STATUS.COMPLETED
            }
            disabled={isBtnLoading.status}
          >
            Check out
          </Button>
        </Popconfirm>
      </>
    );
  };

  const CancelButton = ({ row }) => {
    return (
      <Popconfirm
        placement="topRight"
        title="Are you sure you want to cancel?"
        onConfirm={() => {
          set_data("cancelled", row.appointmentId);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button
          className="cancel"
          loading={isBtnLoading.status && isBtnLoading.id === STATUS.CANCEL}
          disabled={isBtnLoading.status}
        >
          Cancel
        </Button>
      </Popconfirm>
    );
  };

  const CompleteButton = ({ row }) => {
    return (
      <>
        {" "}
        <Button
          onClick={() => {
            history.push(
              "/practitioner/reports/" +
                EncriptString(row.appointmentId.toString()) +
                "/" +
                EncriptString(row.status)
            );
          }}
        >
          Reports and Prescription
        </Button>
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to comlete?"
          onConfirm={() => {
            set_data("completed", row.appointmentId);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            className="checkin"
            loading={
              isBtnLoading.status && isBtnLoading.id === STATUS.COMPLETED
            }
            disabled={isBtnLoading.status}
          >
            Complete/ Finish
          </Button>
        </Popconfirm>
      </>
    );
  };

  const ReportsButton = ({ row }) => {
    return (
      <Button
        onClick={() => {
          history.push(
            "/practitioner/reports/" +
              EncriptString(row.appointmentId.toString()) +
              "/" +
              EncriptString(row.status)
          );
        }}
      >
        Reports and Prescription
      </Button>
    );
  };

  const ApproveButton = ({ row }) => {
    return (
      <Popconfirm
        placement="topRight"
        title="Are you sure you want to confirm?"
        onConfirm={() => {
          set_data(STATUS.APPROVE, row.appointmentId);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button
          className="approve"
          loading={isBtnLoading.status && isBtnLoading.id === STATUS.APPROVE}
          disabled={isBtnLoading.status}
        >
          Confirm
        </Button>
      </Popconfirm>
    );
  };
  //Button UI for each status ends here

  //Returning UI for each status
  switch (row.status) {
    case STATUS.PENDING:
      return (
        <div className="appointment-action-buttons">
          <ApproveButton row={row} />
          <CancelButton row={row} />
        </div>
      );
    case STATUS.APPROVE:
      return (
        <>
          <div className="appointment-action-buttons">
            <RescheduleButton row={row} />
            <CancelButton row={row} />
            {row.auto_checkin_status === 1 &&
            row?.appointmentType !== "Video consultation" &&
            row?.appointmentType !== "Phone consultation" ? (
              <CheckInButton row={row} />
            ) : (
              <CompleteButton row={row} />
            )}
            {(row?.appointmentType === "Video consultation" ||
              row?.appointmentType === "Phone consultation") && (
              <VideoAndAudioButtons row={row} today={today} />
            )}
          </div>
        </>
      );
    case STATUS.CHECKIN:
      return (
        <>
          <div className="appointment-action-buttons">
            {/* <ReportsButton row={row} /> */}
            <CheckoutButton row={row} />
          </div>
        </>
      );
    case STATUS.RESCHEDULE:
      return (
        <div className="appointment-action-buttons">
          <RescheduleButton row={row} />
          <CancelButton row={row} />
          {row.auto_checkin_status === 1 &&
          row?.appointmentType !== "Video consultation" &&
          row?.appointmentType !== "Phone consultation" ? (
            <CheckInButton row={row} />
          ) : (
            <CompleteButton row={row} />
          )}
          {(row?.appointmentType === "Video consultation" ||
            row?.appointmentType === "Phone consultation") && (
            <VideoAndAudioButtons row={row} today={today} />
          )}
        </div>
      );
    case STATUS.COMPLETED:
      return (
        <div className="appointment-action-buttons">
          <ReportsButton row={row} />
        </div>
      );
    default:
      return (
        <div className="appointment-action-buttons">
          <Button>No Actions</Button>
        </div>
      );
  }
};
