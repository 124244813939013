import { getPractitionerRefund, updatePractitionerRefund } from 'Utils/Services/Practitioner/Profile';
import React, { useEffect, useState } from 'react'
import {Form, Button, Card, Input,InputNumber,  message } from 'antd';
import AntdCustomMessage, { antdMessageTypes } from 'components/AntdCustomMessage';

const RefundPercentageTab = () => {
    const [refundPercentage, setRefundPercentage] = useState(0);
    const [editMode, setEditMode] = useState(true);

    const fetchData = async ()=>{
        const response = await getPractitionerRefund();
        response && setRefundPercentage(prev=>response.data.refundPercentage);
        handleEditMode();
    }

    const handleEditMode = ()=> {
        setEditMode(prev=>!prev);
    }

    const validateRefund = (rule, value) => {
      const numberValue = parseFloat(value);
      if (!/^\d+$/.test(value)) {
        return Promise.reject('Please enter digits only!');
      }
      if (isNaN(numberValue)) {
        return Promise.reject('Please enter a valid number!');
      }
      if (numberValue < 0 || numberValue > 100) {
        return Promise.reject('Refund Percentage must be between 0 and 100!');
      }
      return Promise.resolve();
    };

    const handleFormSubmit = async (values) => {
        try{
            const resp = await updatePractitionerRefund({refund_percentage:values.refundPercentage});
            if(resp?.status && resp?.data?.success){
                //message.info("Successfully updated Refund Percentage!");
                AntdCustomMessage({
                  type: antdMessageTypes.INFO,
                  content: "Successfully updated Refund Percentage!"
              });
            }else{
                //message.error("Refund Percentage is not updated.")
                AntdCustomMessage({
                  type: antdMessageTypes.ERROR,
                  content: "Refund Percentage is not updated."
              });
            }
            fetchData();
        }catch(error){
            //message.error(error);
            AntdCustomMessage({
              type: antdMessageTypes.ERROR,
              content: error
          });
        }
    }

    useEffect(()=>{
        fetchData();
    },[])
  return (
    <Card>
        {
            editMode ?(
                <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{
                  refundPercentage: refundPercentage,
                }}
                onFinish={handleFormSubmit}
                // onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Refund Percentage"
                  name="refundPercentage"
                  rules={[
                    {
                      required: true,
                      validator: validateRefund
                    }
                  ]}
                >
                  <input min={1} max={100} />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            ):(
               <>
               <Form.Item
                  label="Refund Percentage"
                >
                  <Input value={refundPercentage} disabled={!editMode}/>
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button type="primary" onClick={handleEditMode}>
                    Edit
                  </Button>
                </Form.Item>
               </> 
            )
        }
    </Card>
  )
}

export default RefundPercentageTab