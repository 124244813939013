/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  Col,
  Row,
  Empty,
  Divider,
  Card,
  Form,
  Select,
  message,
  Button,
  Alert,
  Typography,
  Tabs
} from "antd";
import { timesDropDownArray, days } from "Utils/Constants";
import { getPractitionerConsultingHospitals } from "Utils/Services/Practitioner/Profile";
import { useSchedule } from "./useSchedule";
import ManageSlotsCard from "./ManageSlotsCard";
import {
  getDaySlots,
  getPractitionerScheduleTimings,
  updateSlotDuration,
} from "Utils/Services/Practitioner/ScheduleTimings";
import moment from "moment";
import FullWidthView from "components/FullWidthView";
import Spinner from "components/Spinner";
import { GlobalState } from "context-api";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const { TabPane } = Tabs

const ScheduleTimingsContainer = () => {
  const [myHospitals, setMyHospitals] = useState({ status: false, data: [] });
  const [scheduleTimings, setScheduleTimings] = useState([]);
  const [timings, setTimings] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState("");
  const { isModalOpen, setIsModalOpen, selectedDay, setSelectedDay } =
    useSchedule();
  const [isLoading, setIsLoading] = useState(false);
  const [timeDuration, setTimeDuration] = useState(null);
  const { setHeading, Heading } = useContext(GlobalState);
  const {Title} = Typography;

  // useEffect(() => {
  //   console.log({ timeDuration });
  // }, [timeDuration]);

  const getScheduleTimings = async (clinicId) => {
    setIsLoading(true);

    const response = await getDaySlots({
      clinic_id: clinicId,
      day: selectedDay,
    });
    if (response?.status) {
      setTimeDuration(response?.timeDuration);
      setTimings(response?.slots);
      setScheduleTimings(response?.slotTimings);
    }
    if(!response?.status && response?.message){
      AntdCustomMessage({
        type : antdMessageTypes.ERROR,
        content : response?.message,
      });
    }
    setIsLoading(false);
  };

  const getTimings = (workHours = [], duration = "", day = "sunday") => {
    setTimings([]);
    let timeArray = [];
    workHours.map((item) => {
      if (item.day === day) {
        item.slots.map((slot) => {
          //convert start and end time to 24 hour format
          let startTime = moment(slot.start, "HH:mm").format("HH:mm");
          let endTime = moment(slot.end, "HH:mm").format("HH:mm");
          let i = startTime;

          while (i < endTime) {
            timeArray.push(moment(i, "hh:mm A").format("hh:mm A"));
            i = moment(i, "HH:mm").add(duration, "minutes").format("HH:mm");
          }
        });
      }
    });
    setTimings(timeArray);
  };

  const getData = async () => {
    setIsLoading(true);
    let hospitalsResponse = await getPractitionerConsultingHospitals();
    setMyHospitals(hospitalsResponse);
    if (hospitalsResponse?.status && hospitalsResponse?.data?.length) {
      setSelectedHospital(hospitalsResponse?.data[0].id);
      getScheduleTimings(hospitalsResponse?.data[0].id);
    } else setIsLoading(false);
  };

  const handleDurationChange = async (value) => {
    setTimeDuration(value);
    const response = await updateSlotDuration({ time_duration: value });
    if (response?.status) {
      //message.success("Slot duration updated successfully");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Slot duration updated successfully"
      });
      getScheduleTimings(selectedHospital);
    } else {
      setTimeDuration("10");
      //message.error(response.message);
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: response.message
      });
    }
  };

  useEffect(() => {
    if (selectedHospital) {
      getScheduleTimings(selectedHospital);
    }
  }, [selectedDay,selectedHospital]);

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Schedule Timings");
    }
  }, [setHeading, Heading]);

  if (isLoading)
    return (
      <FullWidthView className="flex items-center justify-center content">
        <Spinner />
      </FullWidthView>
    );
  return (
    <div className="content">
      <Card style={{ minHeight: "70vh" }}>
      <Title level={4}>Pick the hospital</Title>
        <div className="buttons-container-times-marking">
          {myHospitals?.status &&
            myHospitals?.data.map((item) => {
              return (
                  <Button
                    key={item.id}
                    className={selectedHospital === item.id ? "tab" : ""}
                    onClick={() => {
                      setSelectedHospital(item.id);
                    }}
                  >
                    {item.label}
                  </Button>
              )
            }
          )}
        </div>
        <Form layout="vertical" name="nest-messages">
          <Row>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                name={["timeslot"]}
                initialValue={timeDuration}
              >
                <Title level={4}>Select the duration of the time slot</Title>
                <Select
                  optionFilterProp="children"
                  size="large"
                  placeholder="Please select slot duration"
                  value={timeDuration}
                  onChange={(value) =>{ 
                    handleDurationChange(value);
                  }}
                  style={{ width: "100%" }}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {timesDropDownArray.map((time) => (
                    <Select.Option key={time.value} value={time.value}>
                      {time.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        
        {/* <Divider /> */}
        <Title level={4}>Select the day</Title>
        <div className="details-tab-container tab-style">
          <Tabs 
              type="card" 
              defaultActiveKey={selectedDay}   
              onChange={(key)=>{
                  setSelectedDay(key);
              }}  
          >
              {days.map((day) => (
                  <TabPane
                      tab={day.toUpperCase()}
                      key={day}
                      className={`day ${selectedDay === day ? "active" : ""}`}
                  >
                  </TabPane>
              ))}
          </Tabs>
        </div>
        {/* <div className="schedule-days-container">
          {days.map((day) => (
            <div
              className={`day ${selectedDay === day ? "active" : ""}`}
              onClick={() => {
                setSelectedDay(day);
                // getTimings(
                //   scheduleTimings.data.workHours,
                //   scheduleTimings.data.timeDuration,
                //   day
                // );
              }}
              key={day}
            >
              {day}
            </div>
          ))}
        </div> */}
        {/* <Divider /> */}
        <div className="heading-container">
        <Title level={4}>Time Slots</Title>
          <div>
            <Button
              style={{ marginBottom: "10px" }}
              type="secondary"
              disabled={!timeDuration || !selectedHospital}
              onClick={() => setIsModalOpen(true)}
            >
              Manage Slots
            </Button>
            {timeDuration === null && (
              <Alert type="error" message="Please Add Time Duration" className="mb-2"/>
            )}
             {!selectedHospital && (
              <Alert type="error" message="Please pick Hospital" />
            )}
          </div>
        </div>

        {timings?.length ? (
          <div className="slots-container">
            {timings.map((time) => (
              <div className="slot" key={time}>
                {time}
              </div>
            ))}
          </div>
        ) : (
          <FullWidthView className="flex items-center justify-center min-h-half">
            <Empty description="No slots available" />
          </FullWidthView>
        )}
      </Card>
      <Modal
        className="item-details-modal"
        title="Manage Slots"
        visible={isModalOpen}
        footer={null}
        width={700}
        onCancel={() => setIsModalOpen(false)}
        destroyOnClose={true}
      >
        <ManageSlotsCard
          scheduleData={scheduleTimings}
          selectedHospital={selectedHospital}
          fetchData={getScheduleTimings}
        />
      </Modal>
    </div>
  );
};

export default ScheduleTimingsContainer;
