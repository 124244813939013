import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Switch } from "react-router-dom";

import { GlobalStateProvider } from "./context-api";
import RouteIndex from "Routes";
import ErrorBoundary from "Utils/Services/ErrorBoundary";
import "bootstrap/dist/css/bootstrap.css";
import "antd/dist/antd.css";
import "assets/css/paper-dashboard.css";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/css/index.css";
import "assets/css/style.css";
import "assets/css/globalVariables.css";
import { SocketStateProvider } from "socket-context-api";
const hist = createBrowserHistory();

ReactDOM.render(
  <ErrorBoundary>
    <GlobalStateProvider>
      <SocketStateProvider>
        <Router history={hist}>
          <Switch>
            <RouteIndex />
          </Switch>
        </Router>
      </SocketStateProvider>
    </GlobalStateProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
