import React, { useState, useEffect } from "react";
import { Alert, Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { getMyHospitals } from "Utils/Services/Practitioner/Hospitals";
import { useQuickBooking } from "./useQuickBooking";
import ScheduleCard from "./ScheduleCard";

const RegisterPatientContainer = () => {
  const [hospitals, setHospitals] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const {
    setAppointmentDate,
    setSelectedHospital,
    selectedHospital,
    addBookingWithNewPatient,
    isBtnLoading,
    setSelectedAppointmentType,
  } = useQuickBooking();

  const disabledDate = (current)=>{
    return  current && current > new Date()
  }

  useEffect(() => {
    const fetchData = async () => {
      let hospitalsResponse = await getMyHospitals();
      if (hospitalsResponse?.status)
        setHospitals(hospitalsResponse.data.hospitals);
    };

    fetchData();
  }, []);
  return (
    <Form layout="vertical" onFinish={addBookingWithNewPatient}>
      <Alert
        message="Patient not registered"
        description="Please fill the following fields to register user and book appointment."
        type="warning"
        style={{ marginBottom: "1rem" }}
        closable
      />
      <Row gutter={{ sm: 8, lg: 24 }}>
        <Col xs={24} sm={24} lg={12}>
          <Form.Item
            name={["first_name"]}
            label="First Name"
            rules={[{ required: true }]}
          >
            <Input size="large" type="text" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={12}>
          <Form.Item
            name={["last_name"]}
            label="Last Name"
            rules={[{ required: true }]}
          >
            <Input size="large" type="text" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ sm: 8, lg: 24 }}>
        <Col xs={24} sm={24} lg={12}>
          <Form.Item
            name={["gender"]}
            label="Gender"
            rules={[{ required: true }]}
          >
            <Select optionFilterProp="children" size="large">
              <Select.Option value="male">Male</Select.Option>
              <Select.Option value="female">Female</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={12}>
          <Form.Item
            name={["dob"]}
            label="Date of Birth"
            rules={[{ required: true }]}
            
          >
            <DatePicker
              size="large"
              format={"DD MMM YYYY"}
              style={{
                width: "100%",
              }}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ sm: 8, lg: 24 }} style={{ marginTop: "10px" }}>
        <Col xs={24} sm={24} lg={12}>
          <Form.Item
            name={["practitioner_id"]}
            label="Practice"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select hospital"
              optionFilterProp="children"
              size="large"
              onChange={(val) => setSelectedHospital(val)}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {hospitals?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {selectedHospital && appointmentTypes && (
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["booking_type_id"]}
              label="Appointment Type"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Appointment Type"
                optionFilterProp="children"
                size="large"
                onChange={(val) => setSelectedAppointmentType(val)}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {appointmentTypes?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item?.type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={{ sm: 8, lg: 24 }} style={{ marginTop: "10px" }}>
        <Col xs={24} sm={24} lg={14}>
          {selectedHospital && (
            <>
              <p>Appointment Slot:</p>
              <ScheduleCard
                triggerChange={(val) => setAppointmentDate(val)}
                setAppointmentTypes={setAppointmentTypes}
              />
            </>
          )}
        </Col>
      </Row>
      <Row style={{ justifyContent: "flex-start" }}>
        <Button
          style={{ height: 40 }}
          type="primary"
          htmlType="submit"
          loading={isBtnLoading}
          disabled={isBtnLoading}
        >
          Register and Book Appointment
        </Button>
      </Row>
    </Form>
  );
};

export default RegisterPatientContainer;
