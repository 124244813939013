import React, { useEffect, useState } from 'react';
import RegisterBG from 'Images/bg/login.jpg';
import { 
    Form,
    Input,
    Button,
    Select,
    Radio,
    Tabs,
} from  'antd';

import AntdCustomMessage, { antdMessageTypes } from 'components/AntdCustomMessage';
import {format} from 'date-fns';
import { useHistory } from "react-router";
import Cookies from "universal-cookie";
import { getLanguagesList, getPractitionerTypeList, getServicesList, updatePractitionerOnboardingProfile } from 'Utils/Services/Onboarding';
import { getPractitionerInformation } from 'Utils/Services/Practitioner/Profile';
import { DecryptString } from 'Utils/Constants';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';


const {Option} = Select;
const { TabPane } = Tabs;
const PractitionerOnboarding = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const cookies = new Cookies();
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [languages,setLanguages] = useState([]);
    const [practitionerTypes, setPractitionerTypes] = useState([]);
    const [services, setServices] = useState([]);
    
    const [activeTab, setActiveTab] = useState('1');
    const [onboardingData, setOnboardingData] = useState({
        practitioner_type:"",
        gender: "",
        languages_spoken:[],
        about_me:"",
        available_services:[],
        job_title:"",
        medical_license_number:"",
        order_number:"",
        created_at: "",
        updated_at: "",
    });

    let cookieUserData = DecryptString(cookies.get("userdata", { path: "/" }));
    let UserData = cookieUserData && JSON.parse(cookieUserData);
    if (UserData?.type) {
        if (UserData?.type === "hospital") {
          if(UserData?.profile_completed){
            history.push("/hospital/dashboard");
          }
        } else if (UserData?.type === "lab") {
          if(UserData?.profile_completed){
            history.push("/lab/dashboard");
          }
        } else if (UserData?.type === "practitioner") {
          if(UserData?.profile_completed){
            history.push("/practitioner/dashboard");
          }
        }
      }

    const handleFinishTab1 = async () => {
        try {
            await form.validateFields(['gender', 'languages_spoken', 'medical_license_number']);
            setActiveTab('2');
        } catch (errorInfo) {
            // console.log('Validation failed:', errorInfo);
        }
    };

    const handlePrevTab = () => {
        setActiveTab('1');
    };

    const FetchLanguagesList = async () => {
        const languagesResp = await getLanguagesList();
        if(languagesResp?.status){
            const lang = languagesResp?.data?.map(eachLang => ({
                value: eachLang?.id.toString(),
                id: eachLang?.id.toString(),
                label: eachLang?.languages
            }));
            setLanguages([...lang]);
        }  
    };
    const FetchPractitionerTypeList = async () => {
        const practitionerTypesResp = await getPractitionerTypeList();
        if(practitionerTypesResp?.status){
            const pract = practitionerTypesResp?.data?.map(eachPract => ({
                value: eachPract?.id.toString(),
                id: eachPract?.id.toString(),
                label: eachPract?.practitioner,
            }));
            setPractitionerTypes([...pract]);
        }  
    };
    const FetchServicesList = async () => {
        const servicesResp = await getServicesList();
        if(servicesResp?.status){
            const services = servicesResp?.data?.map(eachPract => ({
                value: eachPract?.id.toString(),
                id: eachPract?.id.toString(),
                label: eachPract?.services
            }));
            setServices([...services]);
        }  
    }

    const onFinishFailed = () =>{
        setIsLoading(false);
    };

    const onFinish = async (values) => {
        setIsLoading(true);
        const onboardingResponse = await updatePractitionerOnboardingProfile({
            ...values,
            available_services: JSON.stringify(values.available_services),
            languages_spoken: JSON.stringify(values.languages_spoken),
            created_at: `${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`,
            updated_at: `${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`,
        });
        if(onboardingResponse?.status){
            // AntdCustomMessage({
            //     type:antdMessageTypes.SUCCESS,
            //     content: "Onboarding is successfully completed!"
            // });
            window.alert("Onboarding is successfully completed. Please wait for admin's approval to use your account");
            cookies.remove("userdata", { path: "/" });
            cookies.remove("token", { path: "/" });
            history.push("/login");
            window.location.reload();
        }else{
            AntdCustomMessage({
                type:antdMessageTypes.ERROR,
                content: onboardingResponse?.message || "something went wrong"
            })
        }
        setIsLoading(false);
    };

    useEffect(()=>{
        FetchLanguagesList();
        FetchPractitionerTypeList();
        FetchServicesList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    if(!UserData?.type){
        return <Redirect to="/login" />;
    }
    if(UserData?.profile_completed){
        return <Redirect to="/practitioner/dashboard" />;
    };

  return (
    <div
        style={{
            backgroundImage:`url(${RegisterBG})`,
            backgroundSize: "cover",
            minHeight: '100vh',
            display:"flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        }}
        >
            <div style={{
                minHeight: "50vh",
                width: "80%",
                borderRadius: "10px",
                backgroundColor:"rgba(255,255,255,1)",
                // backdropFilter: "blur(10px)",
                display:"flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <div className='w-75 p-5'>
                    {/* <Typography.Title style={{textAlign:"center"}}>Onboarding</Typography.Title> */}
                    <Form
                        form={form}
                        name="onBoardingForm"
                        initialValues={onboardingData}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                    >
                         <Tabs 
                            activeKey={activeTab}
                            tabBarStyle={{ 
                                background: 'rgba(123, 111, 111, 0.2)', 
                                padding: '8px',
                                width: "100%",
                                fontWeight: "bold",
                                borderRadius: "5px"
                            }}
                        >
                            <TabPane tab="Personal Information" key="1">
                                <Form.Item
                                    name="gender"
                                    label="Gender"
                                    rules={[{ required: true, message: 'Please select your gender!' }]}
                                >
                                    <Radio.Group>
                                    <Radio value="male">Male</Radio>
                                    <Radio value="female">Female</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item
                                    name="languages_spoken"
                                    label="Select atleast one language you know"
                                    rules={[
                                        { required: true, message: 'Please select at least one language!' }
                                    ]}
                                >
                                    <Select 
                                        mode="multiple" 
                                        placeholder="Please select languages"
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                    >
                                    {languages.map((language) => (
                                        <Option key={language.id} value={language.id}>
                                        {language.label}
                                        </Option>
                                    ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Enter your Medical License Number"
                                    name="medical_license_number"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please input your medical license number!',
                                        },
                                    ]}
                                >
                                    <Input type="text"/>
                                </Form.Item>

                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Button 
                                        type="primary" 
                                        onClick={handleFinishTab1}
                                    >
                                        Next
                                    </Button>
                                </Form.Item>
                            </TabPane>
                            <TabPane tab="General Information" key="2">
                                <Form.Item
                                    name="practitioner_type"
                                    label="Select Practitioner Type"
                                    rules={[
                                        { required: true, message: 'Please select practitioner Type' }
                                    ]}
                                >
                                    <Select 
                                        placeholder="Please select Prcatitioner Type"
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                    >
                                        {practitionerTypes.map((practitionerType) => (
                                            <Option key={practitionerType.id} value={practitionerType.id}>
                                            {practitionerType.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Job Title"
                                    name="job_title"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please input job title!',
                                        },
                                    ]}
                                >
                                    <Input type="text"/>
                                </Form.Item>
                                
                                <Form.Item
                                    name="available_services"
                                    label="Select atleast one service"
                                    rules={[
                                        { required: true, message: 'Please select at least one service!' }
                                    ]}
                                >
                                    <Select 
                                        mode="multiple" 
                                        placeholder="Please select services"
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                    >
                                        {services.map((service) => (
                                            <Option key={service.id} value={service.id}>
                                            {service.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="About Me"
                                    name="about_me"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please input about me!',
                                        },
                                    ]}
                                >
                                    <Input type="text"/>
                                </Form.Item>

                                {/* <Form.Item
                                    label="Enter your Order Number"
                                    name="order_number"
                                    // rules={[
                                    //     {
                                    //     required: true,
                                    //     message: 'Please input your order number!',
                                    //     },
                                    // ]}
                                >
                                    <Input type="text" disabled/>
                                </Form.Item> */}
                                {/* <Form.Item
                                    label="Enter today's date"
                                    name="created_at"
                                    rules={[
                                        {
                                        required: true,
                                        message: "Please input today's date!",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const today = new Date();
                                                today.setHours(0, 0, 0, 0);
                                                const selectedDate = new Date(value);
                                                selectedDate.setHours(0, 0, 0, 0);
                                        
                                                if (!value || selectedDate >= today) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Selected date cannot be in the past');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input type="date"/>
                                </Form.Item> */}
                                <div className="d-flex flex-row align-items-center justify-between">
                                
                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Button 
                                        type="primary" 
                                        onClick={handlePrevTab}
                                    >
                                        Prev
                                    </Button>
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Button 
                                        type="primary" 
                                        htmlType="submit"
                                        // disabled={!isValid}
                                        loading={isLoading}
                                    >
                                        Submit
                                    </Button>
                                </Form.Item>
                                </div>
                            </TabPane>
                        </Tabs>     
                    </Form>
                </div>
                
            </div>
        </div>
  )
}

export default PractitionerOnboarding