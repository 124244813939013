import React, { createContext, useEffect, useState, useContext } from "react";
import Cookies from "universal-cookie";
import { io } from "socket.io-client";
import { DecryptString } from "Utils/Constants";

import {
  SocketHospitalNotifications,
  SocketPractitionerNotifications,
  SocketLabNotifications,
} from "Utils/SocketNotifications";
import { GlobalState } from "context-api";
// import ClientKey from "./client-key.pem";
// import ClientCert from "./client-cert.pem";
// import ServerCert from "./server-cert.pem";
export const SocketState = createContext();

export const SocketStateProvider = (props) => {
  const {
    FetchNotifications,
    PractitionerRefreshPage,
    setPractitionerRefreshPage,
    ClinicRefreshPage,
    setClinicRefreshPage,
    LabRefreshPage,
    setLabRefreshPage,
  } = useContext(GlobalState);

  const cookies = new Cookies();
  let cookieUserData = DecryptString(cookies.get("userdata", { path: "/" }));
  let UserData = cookieUserData && JSON.parse(cookieUserData);

  const [SocketIo, setSocketIo] = useState(null);

  useEffect(() => {
    if (UserData?.userid && UserData?.type) {
      // console.log(SocketIo, "socket");
      if (!SocketIo) return;

      SocketIo.on("shopdocSocketNotifications", (data) => {
        // console.log(data);
        if (
          UserData?.type === "hospital" &&
          data?.socketUser === "clinic" &&
          +data?.socketContent?.clinic_id === UserData?.userid
        ) {
          FetchNotifications("hospital");
          SocketHospitalNotifications(data);
          setTimeout(() => {
            setClinicRefreshPage(!ClinicRefreshPage);
          }, 3000);
        } else if (
          UserData?.type === "practitioner" &&
          data?.socketUser === "practitioner" &&
          +data?.socketContent?.practitioner_id === UserData?.userid
        ) {
          FetchNotifications("practitioner");
          SocketPractitionerNotifications(data);
          setTimeout(() => {
            setPractitionerRefreshPage(!PractitionerRefreshPage);
          }, 3000);
        } else if (
          UserData?.type === "lab" &&
          data?.socketUser === "lab" &&
          +data?.socketContent?.lab_id === UserData?.userid
        ) {
          FetchNotifications("lab");
          SocketLabNotifications(data);
          setTimeout(() => {
            setLabRefreshPage(!LabRefreshPage);
          }, 3000);
        }
      });
    }
    // eslint-disable-next-line
  }, [SocketIo, UserData?.userid, UserData?.type]);

  useEffect(() => {
    if (process.env.REACT_APP_PRODUCTION === "false") {
      //local
      setSocketIo(
        io(process.env.REACT_APP_SOCKET_URL, {
          // path: "/booking_api/socket.io",
          // withCredentials: true,
          transports: ["websocket"],
          upgrade: false,
          reconnection: true,
          reconnectionDelay: 1000,
          autoConnect: true,
        })
      );
    } else {
      //production
      setSocketIo(
        io(process.env.REACT_APP_SOCKET_URL, {
          path: "/booking_api/socket.io",
          withCredentials: true,
          transports: ["websocket"],
          upgrade: false,
          reconnection: true,
          reconnectionDelay: 1000,
          autoConnect: true,
        })
      );
    }
  }, []);

  return (
    <SocketState.Provider value={{}}>{props.children}</SocketState.Provider>
  );
};
